<template>
    <div>
        <v-dialog
            v-model="showDialogManagePayments"
            transition="dialog-top-transition"
            persistent
            width="900"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Manage Payments
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    
                    <v-row>
                        <v-col 
                            style="text-align: right;"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="newPayment()"
                                    >
                                        New Payment Configuration
                                    </v-btn>
                                </template>
                                <span>New Payment Configuration</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="listPaymentsConfiguration"
                                :options.sync="options"
                                :loading="loading"
                                :items-per-page="10"
                                :hide-default-footer="true"
                                no-data-text="No Record Found"
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.id" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true,
                                        }"
                                        @customActionEdit="getPayment"
                                        @confirmDelete="confirmDelete"
                                    />
                                </template>
                                <template v-slot:body.append>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Total Percentage</td>
                                        <td>
                                            <label :style="`font-size: 15px !important; color:${colorPercentage}`">{{ totalPercentage }} %</label>
                                        </td>
                                        <td></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="dismiss()"
						>
							Dismiss
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>




        <v-dialog
            v-model="showDialogManagePaymentsForm"
            transition="dialog-top-transition"
            persistent
            width="1000"
            :fullscreen="$vuetify.breakpoint.mobile"
            style="overflow: hidden !important;"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Manage Payments
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                    >
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <label>Description</label>
                                <v-text-field
                                    v-model="paymentsConfigurationRequest.description" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <label>Percentage</label>
                                <v-text-field
                                    v-model="paymentsConfigurationRequest.percentage"
                                    suffix=" %"
                                    v-formatMoney="percFormat"
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <label>Installment</label>
                                <v-text-field
                                    v-model="paymentsConfigurationRequest.parcelNumber"
                                    v-mask="'##'"
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
                        <ActionButtons
                            :request="paymentsConfigurationRequest"
                            :loading="loading"
                            :showButtons="{
                                cancel: true,
                                save: true
                            }"
                            @cancel="cancel"
                            @save="save"
                        />
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>



        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

	export default {

        components: {
            ActionList,
            ActionDialog,
            ActionButtons
        },

        mixins: [Helpers],

        props: {

            id: {
                type: Number,
                default: 0
            },

			showDialogManagePayments: {
				default: false
			},
		},
		
        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Description", value: "description", sortable: true },
                { text: "Installment", value: "parcelNumber", sortable: true },
                { text: "Percentage", value: "percentageFormatted", sortable: true},
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listPaymentsConfiguration: [],


            showDialogManagePaymentsForm: false,
            validForm: true,

            paymentsConfigurationRequest: {
                id: 0,
                description: "",
                percentage: 0,
                parcelNumber: 1,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },
            
            validations: {
                required: required,
            }
        }),

        watch: {
            async "id"() {
				this.getRegisters();
            },
        },

        computed: {

            nextParcel() {
                return this.listPaymentsConfiguration.length + 1;
            },

            totalPercentage() {
                let totalPercentage = 0;

                this.listPaymentsConfiguration.forEach(item => {
                    totalPercentage += item.percentage
                });

                return totalPercentage;
            },

            colorPercentage() {
                if (this.totalPercentage < 100) {
                    return 'var(--color__alert)'
                }
                else if (this.totalPercentage > 100) {
                    return 'var(--color__red)'
                }
                else {
                    return 'var(--color__main)'
                }
            }
        },

        methods: {

            dismiss() {
                if (this.totalPercentage != 100) {
                    this.showToast("error", "Warning!", "The total installments must be equal to 100%.");
                }
                else {
                    this.$emit('methodConfirmToCall');
                    this.$emit('update:showDialogManagePayments', false);
                }
            },
            
            async getRegisters() {

                this.listPaymentsConfiguration = await this.$store.dispatch("servicePaymentsConfigurationModule/ListPaymentsConfigurationByJob", this.id);
            },

            confirmDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will DELETE this Payment Configuration, confirm your decision?',
                    methodConfirm: this.delete,
                    params: id
                };
            },

            async delete(id) {

                this.showLoading();

                const result = await this.$store.dispatch("servicePaymentsConfigurationModule/Delete", id);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            async newPayment() {

                this.paymentsConfigurationRequest = {
                    id: 0,
                    description: "",
                    percentage: 0,
                    parcelNumber: this.nextParcel,
                }

                this.showDialogManagePaymentsForm = true;
            },
            
            async getPayment(id) {

                if (id !== 0) {

                    const response = await this.$store.dispatch("servicePaymentsConfigurationModule/GetById", id);

                    if (response.success) {
                        this.paymentsConfigurationRequest = response.result;
                        this.paymentsConfigurationRequest.percentage = this.paymentsConfigurationRequest.percentageFormatted;
                        
                        this.showDialogManagePaymentsForm = true;
                    }

                }
            },

            async cancel() {
                this.showDialogManagePaymentsForm = false;
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    // this.paymentsConfigurationRequest.id = this.id;
                    this.paymentsConfigurationRequest.idService = this.id;
                    this.paymentsConfigurationRequest.percentage = formaterDecimalBRServer(this.paymentsConfigurationRequest.percentage); 

                    const result = await this.$store.dispatch("servicePaymentsConfigurationModule/CreateUpdate", this.paymentsConfigurationRequest);

                    if (result.success === true) {
                        this.getRegisters();
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    };
</script>
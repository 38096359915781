<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                :lg="showJobInformation == true && showJobSummary == false ? 12 : 7"
                :md="showJobInformation == true && showJobSummary == false ? 12 : 7"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Job Information
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <table style="width: 100%; border: none; margin-top: 10px;">
                                        <tr><td style="width: 15%;">ID</td><td> {{ serviceRequest.idFormatted }}</td></tr>
                                        <tr><td>Created On</td><td> {{ serviceRequest.dateRegisterFormatted }}</td></tr>
                                        <tr><td>Customer</td><td> {{ serviceRequest.customerSelected.name }}</td></tr>
                                        <tr><td>Address</td><td> {{ serviceRequest.addressSelected.address }}</td></tr>
                                        <tr>
                                            <td>Sales Person</td>
                                            <td> 
                                                {{ serviceRequest.userResponsibleSelected != null && serviceRequest.userResponsibleSelected != undefined ? serviceRequest.userResponsibleSelected.name : '' }}
                                                
                                                <v-tooltip
                                                    top
                                                    v-if="isProfileAdmin"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-if="isProfileAdmin"
                                                            style="width: 45px; border: none !important;"
                                                            class="btnActions"
                                                            outlined
                                                            v-on="on"
                                                            color="var(--color__main) !important"
                                                            small
                                                            @click="showDialogSalesPerson()"
                                                        >
                                                            <v-icon v-on="on" small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Update Sales Person</span>
                                                </v-tooltip>

                                            </td>
                                        </tr>
                                        <tr v-if="showCustomerURL"><td>Customer Url</td>
                                            <td> 
                                                <a v-bind:href="serviceRequest.customerUrl" target="_blank">{{ serviceRequest.customerUrl }}</a>
                                                <v-tooltip 
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 45px; border: none !important;"
                                                            class="btnActions"
                                                            outlined
                                                            v-on="on"
                                                            color="var(--color__main) !important"
                                                            small
                                                            @click="copyToClipboard(serviceRequest.customerUrl)"
                                                        >
                                                            <v-icon v-on="on" small>mdi-content-copy</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Copy Url</span>
                                                </v-tooltip>

                                                <span><strong>[Pin Code: {{ serviceRequest.pinCode }}]</strong></span>
                                                <v-tooltip 
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 45px; border: none !important;"
                                                            class="btnActions"
                                                            outlined
                                                            v-on="on"
                                                            color="var(--color__main) !important"
                                                            small
                                                            @click="copyToClipboard(serviceRequest.pinCode)"
                                                        >
                                                            <v-icon v-on="on" small>mdi-content-copy</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Copy pin code</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                        <tr v-if="showReviewReason"><td style="font-weight: normal !important; color: var(--color__main)">Reason: </td><td style="color: var(--color__main)"> This service has some items that need approval</td></tr>
                                        <tr v-if="showClassification"><td>Classification</td>
                                            <td> 
                                                <v-combobox
                                                    style="height: 40px;"
                                                    v-model="classificationSelected"
                                                    :items="listClassification"
                                                    :rules=[validations.required]
                                                    :disabled="showDisabledClassification"
                                                    item-text="description"
                                                    item-value="id"
                                                    clearable
                                                    outlined
                                                    dense
                                                    @change="updateClassification()"
                                                ></v-combobox>
                                            </td>
                                        </tr>
                                        <tr v-if="getConstructionSupervisorName != ''"><td>Construction Supervisor</td>
                                            <td> 
                                                {{ getConstructionSupervisorName }}
                                                <v-tooltip 
                                                    v-if="canChangeSupervisor"
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-if="canChangeSupervisor"
                                                            style="width: 45px; border: none !important;"
                                                            class="btnActions"
                                                            outlined
                                                            v-on="on"
                                                            color="var(--color__main) !important"
                                                            small
                                                            @click="showDialogSupervisor()"
                                                        >
                                                            <v-icon v-on="on" small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Change Supervisor</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                        <tr v-if="isProfileAdmin">
                                            <td>Permit Fee</td>
                                            <td> 
                                                {{ permitFee }}%
                                                <v-tooltip
                                                    top
                                                    v-if="isProfileAdmin"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-if="isProfileAdmin"
                                                            style="width: 45px; border: none !important;"
                                                            class="btnActions"
                                                            outlined
                                                            v-on="on"
                                                            color="var(--color__main) !important"
                                                            small
                                                            @click="showDialogPermitFee()"
                                                        >
                                                            <v-icon v-on="on" small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Update Permit Fee</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>                

                <JobInformationDocuments
                    v-if="showQuickAccess && showQuickAccessAsDocument && projectSelected != null"
                    :id="id"
                    :projectSelected="projectSelected"
                    :title="'Documents'"
                    :isProfileAdmin="isProfileAdmin"
                    :surveyFileId="surveyFileId"
                    :surveyFileType="surveyFileType"
                    :surveyFileName="surveyFileName"
                    :poolStudioFileId="poolStudioFileId"
                    :poolStudioFileType="poolStudioFileType"
                    :poolStudioFileName="poolStudioFileName"
                    :colorSelectionFileId="colorSelectionFileId"
                    :colorSelectionFileType="colorSelectionFileType"
                    :colorSelectionFileName="colorSelectionFileName"
                    :draftingListFiles="[]"
                    :addendumListFiles="addendumListFiles"
                    :permittingListFiles="[]"
                />
            </v-col>
            <v-col
                v-if="showJobSummary == true"
                cols="12"
                lg="5"
                md="5"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Job Total
                        </v-card-title>
                        <v-card-text>
                            
                            <table style="width: 100%; border: none; margin-top: 10px;">
                                <tr v-for="(itemProject, index) in listProjects" :key="index">
                                    <td>{{ itemProject.nameWithVersion }}</td>
                                    <td>{{ itemProject.totalProjectFormatted }}</td>
                                </tr>
                                <tr v-if="addendumListFiles != null && addendumListFiles != undefined && addendumListFiles.length > 0">
                                    <td>Addendum - [{{ serviceRequest.idFormatted }}]</td>
                                    <td>{{ addendumValue }}</td>
                                </tr>
                                <tr>
                                    <td class="grandTotal">Discount</td>
                                    <td class="grandTotal">{{ discount }}</td>
                                </tr>
                                <tr>
                                    <td class="grandTotal">Permit Fee</td>
                                    <td class="grandTotal">{{ permitFee }}%</td>
                                </tr>
                                <tr>
                                    <td class="grandTotal">Grand Total</td>
                                    <td class="grandTotal">{{ grandTotal }}</td>
                                </tr>
                            </table>
                            
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
        
                <JobInformationDocuments
                    v-if="showQuickAccess && showQuickAccessAsDocument == false && projectSelected != null"
                    :id="id"
                    :projectSelected="projectSelected"
                    :isProfileAdmin="isProfileAdmin"
                    :surveyFileId="surveyFileId"
                    :surveyFileType="surveyFileType"
                    :surveyFileName="surveyFileName"
                    :poolStudioFileId="poolStudioFileId"
                    :poolStudioFileType="poolStudioFileType"
                    :poolStudioFileName="poolStudioFileName"
                    :colorSelectionFileId="colorSelectionFileId"
                    :colorSelectionFileType="colorSelectionFileType"
                    :colorSelectionFileName="colorSelectionFileName"
                    :draftingListFiles="[]"
                    :addendumListFiles="addendumListFiles"
                    :permittingListFiles="[]"
                />

                <v-card-text v-if="showPayments == true && payment != null && payment != undefined">

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            <v-col 
                                cols="9"
                                lg="9"
                                md="9"
                                sm="9"
                            >
                                Payments
                            </v-col>
                            <v-col 
                                style="text-align: right;"
                                cols="3"
                                lg="3"
                                md="3"
                                sm="3"
                            >
                                <v-tooltip
                                    left
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            large
                                            color="var(--color__main)"
                                            @click="showDialogManagePayments()"
                                        >
                                            mdi-credit-card-settings-outline
                                        </v-icon>
                                    </template>
                                    <span>Manage Payments</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-title>
                        <v-card-text>
                            
                            <table style="width: 100%; border: none; margin-top: 10px;">
                                <tr>
                                    <td></td>
                                    <td>Calculated</td>
                                    <td>Paid</td>
                                    <td>Remaining</td>
                                </tr>

                                <tr v-for="(itemPayment, index) in payment.listPaymentDetails" :key="index">
                                    <td :style="getValueColor(itemPayment)"><span style="color: gray !important;">[{{ itemPayment.parcelNumber }}]. </span> {{ itemPayment.percentage }}% {{ itemPayment.description }}</td>
                                    <td :style="getValueColor(itemPayment)"> {{ itemPayment.valueCalculatedFormatted }}</td>
                                    <td :style="getValueColor(itemPayment)"> {{ itemPayment.valueFormatted }}</td>
                                    <td :style="getValueColor(itemPayment)"> {{ itemPayment.remainingValueFormatted }}</td>
                                    <td><v-icon v-if="getValueColor(itemPayment) != alertColor" small color="var(--color__main)">mdi-check-circle-outline</v-icon></td>
                                </tr>

                                <tr>
                                    <td class="grandTotal">Total Paid</td>
                                    <td class="grandTotal">{{ payment.totalPaidFormatted }}</td>
                                    <td class="grandTotal"></td>
                                    <td class="grandTotal"></td>
                                    <td class="grandTotal"></td>
                                </tr>
                                <tr>
                                    <td class="grandTotal">Balance</td>
                                    <td class="grandTotal">{{ payment.balanceValueFormatted }}</td>
                                    <td class="grandTotal"></td>
                                    <td class="grandTotal"></td>
                                    <td class="grandTotal"></td>
                                </tr>
                            </table>
                            
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row
            v-if="showProjects == true && listProjects.length > 0"
        >
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>
                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Choose Project
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col
                                    cols="10"
                                    :lg="showButtonsCreateEditProject == true ? 10 : 12"
                                    md="10"
                                    sm="10"
                                >
                                    <v-combobox
                                        style="margin-top: 10px; height: 30px;"
                                        v-model="projectSelected"
                                        :items="listProjects"
                                        item-text="nameWithVersion"
                                        item-value="id"
                                        clearable
                                        outlined
                                        @change="updateProject()"
                                        dense
                                    ></v-combobox>
                                </v-col>

                                <v-col 
                                    style="margin-top: 5px;"
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-tooltip 
                                        v-if="showButtonsCreateEditProject == true"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                style="width: 45px;"
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                color="var(--color__main) !important"
                                                small
                                                @click="showCreateUpdateProject(true)"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Project</span>
                                    </v-tooltip>

                                    <v-tooltip 
                                        v-if="showButtonsCreateEditProject == true"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                style="width: 45px;"
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                color="var(--color__green) !important"
                                                small
                                                :disabled="projectSelected == null || projectSelected == undefined"
                                                @click="showCreateUpdateProject(false)"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit Project Selected</span>
                                    </v-tooltip>

                                    <v-tooltip 
                                        v-if="showButtonsCreateEditVersion == true"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                style="width: 45px;"
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                color="var(--color__cinza_escuro) !important"
                                                small
                                                :disabled="projectSelected == null || projectSelected == undefined"
                                                @click="showListProjectVersions(false)"
                                            >
                                                <v-icon>mdi-cog-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Manage Versions</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row
            v-if="showAddress == true"
        >
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>
                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Choose Address
                        </v-card-title>
                        <v-card-text>
                    
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="addressSelected"
                                        :items="listAddress" 
                                        :rules=[validations.required]
                                        item-text="address"
                                        item-value="id"
                                        clearable
                                        outlined
                                        @change="updateAddress()"
                                        dense
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <!-- Project Dialog (Add or Edit) -->
        <v-dialog
            v-model="showDialogProject"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Project
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-form
                        ref="formProject"
                        v-model="validFormProject"
                        lazy-validation
                    >
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Type of work</label>
                                <v-combobox
                                    v-model="serviceProject.typeWorkSelected"
                                    :items="listTypeWork" 
                                    :rules=[validations.required]
                                    item-text="kindWorkDescription"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>

                            <v-col 
                                v-if="showOptionNewProject"
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Type of Project</label>
                                <v-combobox
                                    v-model="serviceProject.typeProjectSelected"
                                    :items="listTypeNewProject" 
                                    :rules=[validations.required]
                                    item-text="description"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Do you have a Survey?</label>
                                <v-radio-group 
                                    v-model="serviceProject.isSurvey" 
                                    row
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Name</label>
                                <v-text-field
                                    v-model="serviceProject.name" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>                       
                        </v-row>
                    
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Address</label>
                                <v-combobox
                                    v-model="serviceProject.addressSelected"
                                    :items="listAddress" 
                                    :rules=[validations.required]
                                    item-text="address"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceProject.notes"
                                    single-line
                                    rows="4"
                                    row-height="30"
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2"
                            v-if="serviceProject.id != 0 && listProjects.length > 1"
                            style="background-color: var(--color__silver) !important; color: #ffffff !important"
                            outlined
                            @click="confirmRemoveProject()"
                        >
                            Remove
                        </v-btn>

                        <v-btn
                            class="mx-2 buttonCancelDialog"
                            style=""
                            outlined
                            @click="showDialogProject = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2"
                            outlined
                            @click="saveProject()"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>  

        <!-- Manage Versions -->
        <v-dialog
            v-model="showDialogProjectVersion"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Manage Versions
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                            
                    <table style="width: 100%; border: none; margin-top: 10px; font-size: 17px">
                        <tr>
                            <td class="header">Number</td>
                            <td class="header">Name</td>
                            <td class="header">Actions</td>
                        </tr>
                        <tr v-for="(itemVersion, index) in listVersions" :key="index" :style="itemVersion.currentVersion == 1 ? 'font-weight: bold;' : ''">
                            <td>{{ itemVersion.versionFormatted }}</td>
                            <td>{{ itemVersion.versionName }} <v-icon v-if="itemVersion.currentVersion == 1" small color="green">mdi-check-circle-outline</v-icon></td>
                            <td>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="btnActions"
                                            style="width: 30px; height: 30px !important;"
                                            icon 
                                            outlined
                                            v-on="on" 
                                        >
                                            <v-icon small @click="confirmSettingCurrentVersion(itemVersion.id, itemVersion.versionName)">mdi-swap-horizontal</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Switch to this version</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="btnActions"
                                            style="width: 30px; height: 30px !important;"
                                            icon 
                                            outlined
                                            v-on="on" 
                                        >
                                            <v-icon small @click="showCreateUpdateProjectVersion(false, itemVersion.id)">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="btnActions"
                                            :style="listVersions.length <= 1 ? 'width: 30px; height: 30px !important;' : 'width: 30px; height: 30px !important; color: var(--color__red) !important;'"
                                            large
                                            icon 
                                            outlined
                                            v-on="on"
                                            :disabled="listVersions.length <= 1"
                                        >
                                            <v-icon 
                                                small 
                                                @click="confirmProjectVersionDelete(itemVersion.id, itemVersion.versionName)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>                                
                            </td>
                        </tr>
                    </table>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">

                        <v-btn
                            class="mx-2 buttonCancelDialog"
                            style="background-color: var(--color__silver) !important; color: #ffffff !important"
                            outlined
                            @click="showDialogProjectVersion = false"
                        >
                            Back
                        </v-btn>

                        <v-btn
                            class="mx-2"
                            outlined
                            @click="showCreateUpdateProjectVersion(true, 0)"
                        >
                            Save Snapshot
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Manage Versions - Add or Edit -->
        <v-dialog
            v-model="showDialogProjectVersionAddEdit"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Versions
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-form
                        ref="formProjectVersion"
                        v-model="validFormProjectVersionAddEdit"
                        lazy-validation
                    >
                        <v-row
                            v-if="serviceProjectVersion.id == 0"
                        >
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-switch
                                    v-model="serviceProjectVersion.startFromScratch"
                                    label="Start from scratch"
                                    color="var(--color__main)"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Version Name</label>
                                <v-text-field
                                    v-model="serviceProjectVersion.versionName" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceProjectVersion.versionNotes"
                                    single-line
                                    rows="4"
                                    row-height="30"
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">

                        <v-btn
                            class="mx-2 buttonCancelDialog"
                            outlined
                            @click="showDialogProjectVersionAddEdit = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2"
                            outlined
                            @click="saveProjectVersion()"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        /> 
        
        <DialogSelectSupervisor
            :id="dialogSupervisor.id"
            :setSupervisor="dialogSupervisor.setSupervisor"
            :idStatusToUpdate="dialogSupervisor.idStatusToUpdate"
            v-on:methodConfirmToCall="dialogSupervisor.methodConfirm"
            :showDialogSupervisor.sync="dialogSupervisor.show"
        />
        
        <DialogUpdatePermitFee
            :id="dialogPermitFee.id"
            :setPermitFee="dialogPermitFee.setPermitFee"
            v-on:methodConfirmToCall="dialogPermitFee.methodConfirm"
            :showDialogPermitFee.sync="dialogPermitFee.show"
        />

        <DialogUpdateSalesPerson
            :id="dialogSalesPerson.id"
            :setSalesPerson="dialogSalesPerson.setSalesPerson"
            v-on:methodConfirmToCall="dialogSalesPerson.methodConfirm"
            :showDialogSalesPerson.sync="dialogSalesPerson.show"
        />

        <DialogManagePayments
            :id="dialogManagePayments.id"
            v-on:methodConfirmToCall="dialogManagePayments.methodConfirm"
            :showDialogManagePayments.sync="dialogManagePayments.show"
        />
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import { formaterDecimalBR } from '@/utilities/Utils';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";
    import { orderBy } from '@/utilities/Utils';
    import { URL_ATTACHMENT, URL_ATTACHMENT_LOAD_CALCULATION } from "@/utilities/Enums";
    import DialogSelectSupervisor from "@/components/Services/DialogSelectSupervisor";
    import DialogUpdatePermitFee from "@/components/Services/DialogUpdatePermitFee";
    import DialogUpdateSalesPerson from "@/components/Services/DialogUpdateSalesPerson";
    import DialogManagePayments from "@/components/Services/DialogManagePayments";
    import JobInformationDocuments from "@/components/Services/JobInformationDocuments";
    import { TYPE_OF_WORK } from "@/utilities/Enums";

	export default {

        components: {
            ActionDialog,
            DialogSelectSupervisor,
            DialogUpdatePermitFee,
            DialogUpdateSalesPerson,
            DialogManagePayments,
            JobInformationDocuments
        },

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },

			showJobInformation: {
				default: true
			},
            
			showJobSummary: {
				default: false
			},
            
			showProjects: {
				default: true
			},

			showCustomerURL: {
				default: true
			},

			showReviewReason: {
				default: false
			},

			showAddress: {
				default: false
			},

			showButtonsCreateEditProject: {
				default: true
			},

			showButtonsCreateEditVersion: {
				default: true
			},

			showClassification: {
				default: false
			},

			showDisabledClassification: {
				default: false
			},

            project: {
                default: null
            },

            refreshJob: {
                default: false
            },

            showQuickAccess: {
                default: true
            },

            canChangeSupervisor: {
                default: false
            },

            showQuickAccessAsDocument: {
                default: false
            },

            showPayments: {
                default: false
            },

            fromTrackerPage: {
                default: false
            },

            managePayments: {
                default: false
            }
		},

		
        data: () => ({
            
            URL_ATTACHMENT,
            URL_ATTACHMENT_LOAD_CALCULATION,
			
            serviceRequest: {
                id: 0,
                userNameRegister: "",
                dateRegisterFormatted: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                userResponsibleSelected: {
                    name: ""
                },
                customerUrl: ""
            },

            listProjects: [],
            projectSelected: null,
            showDialogProject: false,
            validFormProject: true,
            
            listVersions: [],
            showDialogProjectVersion: false,
            showDialogProjectVersionAddEdit: false,
            validFormProjectVersionAddEdit: true,

            listTypeWork: [],

            listTypeNewProject: [
                { id: 1, description: 'New Pool' },
                { id: 2, description: 'BBQ' },
                { id: 3, description: 'Pergola' },
            ],

            serviceProject: {
                id: 0,
                idServiceProjectReference: 0,
                idCustomer: 0,
                typeWorkSelected: {
                    id: 3,
                    kindWorkDescription: 'Service'
                },
                typeProjectSelected: null,
                idKindWork: 3,
                pool: true,
                bbq: false,
                pergola: false,
                isSurvey: 1,
                step: 1,
                name: "",
                notes: "",
                addressSelected: null,
            },

            serviceProjectVersion: {
                idServiceProject: 0,
                idServiceProjectReference: 0,
                startFromScratch: false,
                idService: 0,
                versionName: '',
                versionNotes: ''
            },

            addressSelected: null,
            listAddress: [],

            grandTotal: "$ 0,00",
            discount: "$ 0.00",
            permitFee: "$ 0.00",

            classificationSelected: null,
            listClassification: [
                { id: 1,  description: '1 - Highest Priority' },
                { id: 2,  description: '2 - High Priority' },
                { id: 3,  description: '3 - Medium Priority' },
                { id: 4,  description: '4 - Low Priority' }
            ],
            
            surveyFileId: 0,
            surveyFileType: 'application/pdf',
            surveyFileName: '',
            
            poolStudioFileId: 0,
            poolStudioFileType: 'application/pdf',
            poolStudioFileName: '',
            
            colorSelectionFileId: 0,
            colorSelectionFileType: 'application/pdf',            
            colorSelectionFileName: '',

            addendumListFiles: [],
            permittingListFiles: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogSupervisor: {
                id: 0,
                setSupervisor: null,
                idStatusToUpdate: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogPermitFee: {
                id: 0,
                setPermitFee: null,
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogSalesPerson: {
                id: 0,
                setSalesPerson: null,
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogManagePayments: {
                id: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },

            validations: {
                required: required
            },

            og: Math.pow(10, 2),

            payment: null,
            alertColor: "color: var(--color__alert) !important;",
            mainColor: "color: var(--color__main) !important;",
            
            TYPE_OF_WORK
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            showOptionNewProject() {

                if (this.serviceProject.typeWorkSelected != null && 
                    this.serviceProject.typeWorkSelected != undefined) {
                    return this.serviceProject.typeWorkSelected.id == this.TYPE_OF_WORK.REMODEL || 
                           this.serviceProject.typeWorkSelected.id == this.TYPE_OF_WORK.NEW_PROJECT;
                }
                else {
                    return false;
                }
            },

            isProfileAdmin() {
                if (this.fromTrackerPage == true) {
                    return false;
                }
                else {
                    return (this.userLoggedGetters != null && 
                        this.userLoggedGetters.profile != null && 
                        this.userLoggedGetters.profile.systemAdmin != null && 
                        this.userLoggedGetters.profile.systemAdmin == 1) ||
                       (this.userLoggedGetters != null && this.userLoggedGetters.id == 0)
                }
                
            },

            getConstructionSupervisorName() {

                return this.projectSelected != null && 
                       this.projectSelected != undefined &&
                       this.projectSelected.constructionSupervisorName != null &&
                       this.projectSelected.constructionSupervisorName != undefined ? 
                       this.projectSelected.constructionSupervisorName.toString().trim() : '';
            },
        },

        watch: {
            async "id"() {
				this.getRegister();
            },

            async "refreshJob"() {
				this.getRegister();
            },

            async "project"() {

                if (this.project != null && this.project != undefined) {
                    this.projectSelected = this.project;
                }
            }
        },

        methods: {

            getValueColor(itemPayment) {
                if (itemPayment.valueFormatted != itemPayment.valueCalculatedFormatted) {
                    return this.alertColor;
                }
                else {
                    return this.mainColor;
                }
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },
			
            async updateProject(checkValidation = false) {

                if (this.projectSelected != null && this.projectSelected != undefined) {
                    this.listProjects.forEach(itemProject => {
                        if (itemProject.id == this.projectSelected.id) {
                            this.projectSelected = itemProject;

                            let itemClassification = this.listClassification.filter(cla => cla.id == itemProject.classification)[0];
                            
                            if (itemClassification != null && itemClassification != undefined) {
                                this.classificationSelected = itemClassification;
                            }

                            this.getAttachments();
                        }
                    })
                }

				this.$emit('changeProject', this.projectSelected, this.listProjects, checkValidation)
            },
			
            async updateAddress() {

                if (this.addressSelected != null && this.addressSelected != undefined) {
                    this.listAddress.forEach(itemAddress => {
                        if (itemAddress.id == this.addressSelected.id) {
                            this.addressSelected = itemAddress
                        }
                    })
                }

				this.$emit('changeAddress', this.addressSelected, this.listAddress)
            },

            async updateClassification() {
                
                let idServiceProject = await this.getIDProject();

                let classification = '';

                if (this.classificationSelected != null && this.classificationSelected != undefined) {
                    classification = this.classificationSelected.id;
                }

                if (classification != null && classification != undefined) {

                    this.$store.dispatch("serviceModule/UpdateClassification", { idServiceProject, classification });
                }
            },

            async getRegister() {

				const response = await this.$store.dispatch("serviceModule/GetById", this.id);

				this.serviceRequest = response.result;

                this.loadListProjects();

                if (this.showAddress) {
                    this.loadAddress();
                }

                if (this.showPayments == true) {
                    
                    const requestFilter = {
                        idService: this.serviceRequest.id,
                        listPendingPayments: true
                    }

                    const listPayments = await this.$store.dispatch("servicePaymentsModule/ListPayments", requestFilter);
                    if (listPayments != null && listPayments != undefined) {
                        this.payment = listPayments[0];
                    }
                }

                const listTypeWork = await this.$store.dispatch("serviceModule/ListTypeOfWorks");
                this.listTypeWork = listTypeWork.filter(tow => tow.id >= 1);
            },

            async loadListProjects(checkValidation = false) {

                this.listProjects = await this.$store.dispatch("serviceModule/ListProject", this.serviceRequest.id);

                if (this.listProjects != null && this.listProjects != undefined) {
                    this.projectSelected = this.listProjects[0]
                }
                
                this.updateProject(checkValidation);

                let totalProject = 0;
                let totalAddendum = 0;

                this.listProjects.forEach(itemProject => {
                    totalProject += itemProject.totalProject
                });

                const idServiceProject = await this.getIDProject();

                const listAddendum = await this.$store.dispatch("serviceModule/ListAddendum", idServiceProject);

                let totalAddendumJobTotal = 0;

                listAddendum.forEach(itemProject => {
                    totalAddendumJobTotal += itemProject.total
                });

                this.addendumValue = `$ ${formaterDecimalBR(Math.floor(totalAddendumJobTotal * this.og) / this.og)}`;

                this.discount = formaterDecimalBR(this.serviceRequest.discount);
                this.permitFee = formaterDecimalBR(this.serviceRequest.permitFee);

                if (this.serviceRequest.grandTotal > 0) {
                    totalProject = this.serviceRequest.grandTotal;
                }

                let grandTotal = totalProject + totalAddendum + totalAddendumJobTotal;
                
                let permitFeeValue = 0;
                if (this.permitFee > 0) {
                    permitFeeValue = Math.floor(grandTotal * (this.permitFee / 100));
                    grandTotal += permitFeeValue;
                }

                this.grandTotal = `$ ${formaterDecimalBR( (grandTotal * this.og) / this.og )}`;
            },

            async loadAddress() {
                
                if (this.serviceRequest.customerSelected != null && this.serviceRequest.customerSelected != undefined) {
                    let idCustomer = this.serviceRequest.customerSelected.id;
                    if (idCustomer != 0 && idCustomer != null && idCustomer != undefined) {
                        this.listAddress = await this.$store.dispatch(`customerModule/ListAddress`, idCustomer);

                        if (this.listAddress != null && this.listAddress != undefined && this.listAddress.length == 1) {
                            this.addressSelected = this.listAddress[0]
                        }

                        this.updateAddress();
                    }
                }
            },

            //PROJECT
            async showCreateUpdateProject(isNewProject) {

                this.showLoading();

                if (isNewProject) {
                    this.serviceProject = {
                        id: 0,
                        idServiceProjectReference: 0,
                        idCustomer: 0,
                        idKindWork: 3,
                        typeWorkSelected: {
                            id: this.TYPE_OF_WORK.SERVICE,
                            kindWorkDescription: 'Service'
                        },
                        typeProjectSelected: null,
                        pool: 0,
                        bbq: 0,
                        pergola: 0,
                        isSurvey: 1,
                        name: "",
                        notes: "",
                        addressSelected: null,
                    };
                }
                else {
                    const idServiceProject = await this.getIDProject();

                    const responseProject = await this.$store.dispatch("serviceModule/GetServiceProjectById", idServiceProject);

                    this.serviceProject = responseProject.result;

                    let typeProjectSelected = this.listTypeNewProject.filter(typ => typ.id == 1)[0];

                    if (this.serviceProject.bbq == 1) {
                        typeProjectSelected = this.listTypeNewProject.filter(typ => typ.id == 2)[0];
                    }
                    else if (this.serviceProject.pergola == 1) {
                        typeProjectSelected = this.listTypeNewProject.filter(typ => typ.id == 3)[0];
                    }

                    this.serviceProject.typeProjectSelected = typeProjectSelected
                }
                
                this.loadAddress();

                this.showDialogProject = true;

                this.hideLoading();
            },

            async getAttachments() {

                this.surveyFileId = 0;
                this.surveyFileName = '';
                this.poolStudioFileId = 0;
                this.poolStudioFileName = '';
                this.colorSelectionFileId = 0;
                this.colorSelectionFileName = '';

                let idServiceProject = await this.getIDProject();

                let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: idServiceProject });

                //SURVEY
                let itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];
                if (itemSessionDetailSurveyType != null && itemSessionDetailSurveyType != undefined) {
                    this.surveyFileId = itemSessionDetailSurveyType.id;
                    this.surveyFileType = itemSessionDetailSurveyType.fileType;
                    this.surveyFileName = itemSessionDetailSurveyType.fileName;
                }

                let itemSessionDetailPoolStudioDXF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_DXF.value)[0];
                if (itemSessionDetailPoolStudioDXF != null && itemSessionDetailPoolStudioDXF != undefined) {
                    this.poolStudioFileId = itemSessionDetailPoolStudioDXF.id;
                    this.poolStudioFileType = itemSessionDetailPoolStudioDXF.fileType;
                    this.poolStudioFileName = itemSessionDetailPoolStudioDXF.fileName;
                }

                listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.COLOR_SELECTION.value, idKey: idServiceProject });

                let listFilesColorSelection = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.COLOR_SELECTION.value);
                if (listFilesColorSelection != null && listFilesColorSelection != undefined) {
                    
                    let listColorSelectionFiles = [];

                    listFilesColorSelection.forEach(itemAddendum => {
                        listColorSelectionFiles.push({
                            id: itemAddendum.id,
                            fileName: itemAddendum.fileName,
                            fileType: itemAddendum.fileType,
                            createdOn: itemAddendum.dateRegisterFormatted,
                            userName: itemAddendum.userNameRegister
                        });
                    });

                    listColorSelectionFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });

                    if (listColorSelectionFiles.length > 0) {
                        this.colorSelectionFileId = listColorSelectionFiles[0].id;
                        this.colorSelectionFileType = listColorSelectionFiles[0].fileType;
                        this.colorSelectionFileName = listColorSelectionFiles[0].fileName;
                    }
                }

                listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE.value, idKey: this.serviceRequest.id });

                if (listAttachments != null && listAttachments != undefined && listAttachments.length > 0) {
                    this.addendumListFiles = [];
                    const addendumListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ADDENDUM.value);

                    if (addendumListFiles != null && addendumListFiles != undefined) {

                        addendumListFiles.forEach(itemFile => {

                            this.addendumListFiles.push({
                                id: itemFile.id,
                                fileType: itemFile.fileType,
                                fileName: itemFile.fileName,
                            });
                        })
                    }

                }
            },

            async confirmRemoveProject() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will REMOVE this project [${this.serviceProject.name}] and all items and versions related to it, confirm your decision?`,
                    methodConfirm: this.removeProject,
                    params: 0
                };

            },

            async removeProject() {
                const result = await this.$store.dispatch("serviceModule/RemoveProject", { id: this.serviceProject.id, removeAllVersions: 1 });

                if (result.success === true) {
                    this.loadListProjects();
                    this.showToast("success", "Success!", result.message);
                    this.showDialogProject = false;
                    this.projectSelected = null;
                    return true;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            async saveProject() {

                await this.$refs.formProject.validate();
                
                if (
                    (this.serviceProject.typeWorkSelected != null && 
                     this.serviceProject.typeWorkSelected != undefined && 
                     this.serviceProject.typeWorkSelected.id != this.TYPE_OF_WORK.SERVICE) && 
                    (this.serviceProject.typeProjectSelected == null || 
                     this.serviceProject.typeProjectSelected == undefined)
                   ) 
                {
                    this.showToast("error", "Warning!", "You must select the type of project [Pool, BBQ or Pergola]!");
                }
                else {
                    if (this.validFormProject === false) {
                        this.showToast("error", "Warning!", "There are inconsistencies in the project form. Please review!");
                        return false;
                    }
                    else {

                        if (this.serviceProject.typeWorkSelected != null && this.serviceProject.typeWorkSelected != undefined) {
                            this.serviceProject.idKindWork = this.serviceProject.typeWorkSelected.id;
                        }

                        if (this.serviceProject.typeProjectSelected != null && this.serviceProject.typeProjectSelected != undefined) {
                            if (this.serviceProject.typeProjectSelected.id == 1) {
                                this.serviceProject.pool = 1;
                                this.serviceProject.bbq = 0;
                                this.serviceProject.pergola = 0;
                            }
                            else if (this.serviceProject.typeProjectSelected.id == 2) {
                                this.serviceProject.pool = 0;
                                this.serviceProject.bbq = 1;
                                this.serviceProject.pergola = 0;
                            }
                            else if (this.serviceProject.typeProjectSelected.id == 3) {
                                this.serviceProject.pool = 0;
                                this.serviceProject.bbq = 0;
                                this.serviceProject.pergola = 1;
                            }
                        }

                        if (this.serviceProject.idKindWork == this.TYPE_OF_WORK.SERVICE) {
                            this.serviceProject.pool = 0;
                            this.serviceProject.bbq = 0;
                            this.serviceProject.pergola = 0;
                        }

                        this.serviceProject.idCustomer = this.serviceRequest.customerSelected.id;
                        this.serviceProject.isSameAddress = 1;
                        this.serviceProject.idService = this.id;

                        if (this.serviceProject.addressSelected != null && this.serviceProject.addressSelected != undefined) {
                            this.serviceProject.idAddress = this.serviceProject.addressSelected.id;
                        }

                        const result = await this.$store.dispatch("serviceModule/CreateUpdateServiceProject", this.serviceProject);

                        if (result.success === true) {
                            this.loadListProjects(true);
                            this.showToast("success", "Success!", result.message);
                            this.showDialogProject = false;
                            return true;
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                            return false;
                        }
                    }
                }
            },
            
            //VERSION
            async loadVersions() {

                let idServiceProject = await this.getIDProject();

                this.listVersions = await this.$store.dispatch("serviceModule/ListProjectVersions", idServiceProject);

            },

            async showListProjectVersions() {

                this.showLoading();

                this.loadVersions();
            
                this.showDialogProjectVersion = true;

                this.hideLoading();
            },

            async showCreateUpdateProjectVersion(isNewVersion, idServiceProject) {

                this.showLoading();

                if (isNewVersion) {
                    this.serviceProjectVersion = {
                        id: idServiceProject,
                        idServiceProjectReference: await this.getIDProject(),
                        idService: this.id,
                        startFromScratch: false,
                        versionName: "",
                        versionNotes: "",
                    };
                }
                else {
                    let responseProject = await this.$store.dispatch("serviceModule/GetServiceProjectById", idServiceProject);

                    this.serviceProjectVersion = responseProject.result;
                }
                
                this.showDialogProjectVersionAddEdit = true;

                this.hideLoading();
            },

            async saveProjectVersion() {

                await this.$refs.formProjectVersion.validate();

                if (this.validFormProjectVersionAddEdit === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the version form. Please review!");
                    return false;
                }
                else {

                    const result = await this.$store.dispatch("serviceModule/CreateUpdateServiceProjectVersionSnapShot", this.serviceProjectVersion);

                    if (result.success === true) {
                        this.loadVersions();
                        this.showToast("success", "Success!", result.message);
                        this.showDialogProjectVersionAddEdit = false;
                        return true;
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                        return false;
                    }
                }

            },

            async confirmProjectVersionDelete(idServiceProject, versionName) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will REMOVE this version [${versionName}] and all items related to it, confirm your decision?`,
                    methodConfirm: this.removeVersion,
                    params: idServiceProject
                };
            },

            async removeVersion(idServiceProject) {
                const result = await this.$store.dispatch("serviceModule/RemoveProject", { id: idServiceProject, removeAllVersions: 0 });

                if (result.success === true) {
                    this.loadListProjects();
                    this.loadVersions();
                    this.showToast("success", "Success!", "Version deleted with successfully!");
                    this.showDialogProject = false;
                    this.projectSelected = null;
                    return true;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            async confirmSettingCurrentVersion(idServiceProject, versionName) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will SWITCH for this version [${versionName}], confirm your decision?`,
                    methodConfirm: this.setCurrentVersion,
                    params: idServiceProject
                };
            },

            async setCurrentVersion(idServiceProject) {
                const result = await this.$store.dispatch("serviceModule/SetCurrentVersion", { idServiceProject, currentVersion: 1 });

                if (result.success === true) {
                    await this.loadListProjects();
                    await this.loadVersions();
                    this.showToast("success", "Success!", "Version switched with successfully!");
                    this.showDialogProject = false;
                    this.showDialogProjectVersion = false;
                    this.showDialogProjectVersionAddEdit = false;

                    this.listProjects.forEach(itemProject => {
                        if (itemProject.id == idServiceProject) {
                            this.projectSelected = itemProject;
                            this.updateProject();
                        }
                    })

                    return true;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            async showDialogSupervisor() {
                let idServiceProject = await this.getIDProject();

                let userSelected = null;

                if (this.projectSelected != null && this.projectSelected != undefined) {

                    userSelected = {
                        id: this.projectSelected.idUserSupervisor,
                        name: this.projectSelected.constructionSupervisorName
                    }
                }

                this.dialogSupervisor = {                    
                    id: idServiceProject,
                    setSupervisor: userSelected,
                    idStatusToUpdate: 18,
                    show: true,
                    methodConfirm: ( () => { this.getRegister() })
                }
            },

            async showDialogPermitFee() {
                this.dialogPermitFee = {                    
                    id: this.id,
                    setPermitFee: formaterDecimalBR(this.serviceRequest.permitFee),
                    show: true,
                    methodConfirm: ( () => { this.getRegister() })
                }
            },

            async showDialogManagePayments() {
                this.dialogManagePayments = {                    
                    id: this.id,
                    show: true,
                    methodConfirm: ( () => { this.getRegister() })
                }
            },

            async showDialogSalesPerson() {

                this.dialogSalesPerson = {                    
                    id: this.id,
                    setSalesPerson: {
                        id: this.serviceRequest.userResponsibleSelected.id,
                        userName: this.serviceRequest.userResponsibleSelected.name
                    },
                    show: true,
                    methodConfirm: ( () => { this.getRegister() })
                }
            },
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    tbody > tr:hover {
        background-color: transparent !important;
    }

    .selectedRow, .selectedRow:hover {
        background-color: var(--color__main) !important;
        color: #fff;
        font-weight: bold;
    }

    td {
        padding: 3px;
    }

    .grandTotal {
        color: var(--color__main) !important; 
        font-size: 18px;
        font-weight: bold;
        padding-top: 10px;
    }

</style>
<template>
    <div>
        <v-card-text>
            <v-card
                class="mx-auto"
            >
                <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    {{ title }}
                </v-card-title>
                <v-card-text>
                    
                    <v-row>
                        <v-col
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="openGallery()"
                                        >
                                            <v-icon v-on="on" large>mdi-camera-burst</v-icon>
                                        </v-btn>
                                        <label>Gallery</label>
                                    </div>
                                </template>
                                <span>Gallery</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="openScopeOfWork()"
                                        >
                                            <v-icon v-on="on" large>mdi-clipboard-list-outline</v-icon>
                                        </v-btn>
                                        <label>Scope of Work</label>
                                    </div>
                                </template>
                                <span>Scope of Work</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="isProfileAdmin"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="showDialogTotalProposal = true;"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Scope of Work Document</label>
                                    </div>
                                </template>
                                <span>Scope of Work Document</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="isProfileAdmin"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT_CONTRACT, id, 'application/pdf', 'Contract.pdf');"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Contract</label>
                                    </div>
                                </template>
                                <span>Contract</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="isProfileAdmin"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY, id, 'application/pdf', 'HomeownerResponsibility.pdf');"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Homeowner Responsability</label>
                                    </div>
                                </template>
                                <span>Homeowner Responsability</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="surveyFileId > 0"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top >
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT, surveyFileId, surveyFileType, surveyFileName)"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Survey</label>
                                    </div>
                                </template>
                                <span>Survey</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="poolStudioFileId > 0"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top >
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT, poolStudioFileId, poolStudioFileType, poolStudioFileName)"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Pool Studio</label>
                                    </div>
                                </template>
                                <span>Pool Studio</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT_LOAD_CALCULATION, id, 'application/pdf', PDF_NAMES.LOAD_CALCULATION)"
                                        >
                                            <v-icon link v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Load Calculation</label>
                                    </div>
                                </template>
                                <span>Load Calculation</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT_ENGINEERING_SHEET, getIDServiceProject(), 'application/pdf', PDF_NAMES.ENGINEER_SHEET)"
                                        >
                                            <v-icon link v-on="on" large>mdi-google-spreadsheet</v-icon>
                                        </v-btn>
                                        <label>Engineering Sheet</label>
                                    </div>
                                </template>
                                <span>Engineering Sheet</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="colorSelectionFileId > 0"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top >
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="downloadFile(URL_ATTACHMENT, colorSelectionFileId, colorSelectionFileType, colorSelectionFileName)"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Color Selection</label>
                                    </div>
                                </template>
                                <span>Color Selection</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="showDraftingFiles"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top >
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="openDraftingFiles()"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Drafting Files</label>
                                    </div>
                                </template>
                                <span>Drafting Files</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="addendumListFiles != null && addendumListFiles != undefined && addendumListFiles.length > 0"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top >
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">

                                        <v-menu
                                            left
                                            top
                                            v-on="on"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    class="btnActions buttonMoreOptions"
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    small
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>

                                                <v-list-item
                                                    v-for="(item, index) in addendumListFiles" :key="index"
                                                    @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                >
                                                    <v-list-item-title><v-icon left>mdi-file-document-outline</v-icon> {{ item.fileName }} </v-list-item-title>
                                                </v-list-item>

                                            </v-list>
                                        </v-menu>
                                        <label>Addendum Files</label>
                                    </div>
                                </template>
                                <span>Addendum Files</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            v-if="showDraftingFiles"
                            style="padding-top: 10px; text-align: center;"
                            cols="4"
                            lg="2"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top >
                                <template v-slot:activator="{ on }">
                                    <div class="quickAccessButtons">
                                        <v-btn 
                                            style="width: 45px; border: none !important;"
                                            class="btnActions"
                                            outlined
                                            v-on="on"
                                            color="var(--color__main) !important"
                                            small
                                            @click="openPermittingFiles()"
                                        >
                                            <v-icon v-on="on" large>mdi-file-document-outline</v-icon>
                                        </v-btn>
                                        <label>Permitting Files</label>
                                    </div>
                                </template>
                                <span>Permitting Files</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    
                </v-card-text>

            </v-card>
            
        </v-card-text>

        <!-- Confirmation Download Proposal -->
        <v-dialog
            v-model="showDialogTotalProposal"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >

            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Download PDF Proposal
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-switch 
                                v-model="showTotalProposal"
                                label="Show only the total value of the proposal?"
                                :value=true
                                color="var(--color__main)"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="isProfileAdmin"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-switch 
                                v-model="showBreakdown"
                                label="Show breakdown?"
                                :value=true
                                color="var(--color__main)"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss"
                            style=""
                            outlined
                            @click="showDialogTotalProposal = false"
                        >
                            Dismiss
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            outlined
                            @click="downloadProposal()"
                        >
                            Download
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :idServiceProject="dialogShowFiles.idServiceProject"
            :title.sync="dialogShowFiles.title"
            :showAsCarrousel.sync="dialogShowFiles.showAsCarrousel"
            :showFilters.sync="dialogShowFiles.showFilters"
            :reload="dialogShowFiles.reload"
            :listParams="dialogShowFiles.listParams"
        />

        <ScopeOfWork 
            :showScopeOfWork.sync="dialogScopeOfWork.show"
            :idAux="dialogScopeOfWork.idAux"
            :idServiceProject="dialogScopeOfWork.idServiceProject"
        />
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ShowFiles from "@/components/File/ShowFiles";
    import ScopeOfWork from "@/components/Services/ScopeOfWork";

    import { 
        URL_ATTACHMENT, 
        URL_ATTACHMENT_LOAD_CALCULATION,
        URL_ATTACHMENT_ENGINEERING_SHEET,
        URL_ATTACHMENT_CONTRACT,
        URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY,
        PDF_NAMES,
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT,
        PROJECT_STATUS
    } from "@/utilities/Enums";

	export default {

        mixins: [Helpers],

        components: {
            ShowFiles,
            ScopeOfWork
        },

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            projectSelected: {
                type: Object,
                default: null
            },

            title: {
                default: 'Quick Access'
            },

			isProfileAdmin: {
				default: false
			},
            
			surveyFileId: {
				default: 0
			},           

            surveyFileType: {
                default: 'application/pdf'
            },

            surveyFileName: {
                default: ''
            },
            
            poolStudioFileId: {
                default: 0,
            },

            poolStudioFileType: {
                default: 'application/pdf'
            },

            poolStudioFileName: {
                default: ''
            },

            colorSelectionFileId: {
                default: 0
            },

            colorSelectionFileType: {
                default: 'application/pdf'
            },

            colorSelectionFileName: {
                default: ''
            },

            draftingListFiles: {
                default: []
            },

            addendumListFiles: {
                default: []
            },

            permittingListFiles: {
                default: []
            },
		},
		
        data: () => ({
            
            URL_ATTACHMENT,
            URL_ATTACHMENT_LOAD_CALCULATION,
            URL_ATTACHMENT_ENGINEERING_SHEET,
            URL_ATTACHMENT_CONTRACT,
            URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY,
            PDF_NAMES,
            PROJECT_STATUS,

            showDialogTotalProposal: false,

            showTotalProposal: true,
            showBreakdown: true,
            
            dialogShowFiles: {
                show: false,
                idServiceProject: 0,
                title: '',
                showAsCarrousel: true,
                showFilters: false,
                reload: 0,
                listParams: [],
            },
            
            dialogScopeOfWork: {
                show: false,
                idAux: 0,
                idServiceProject: 0,
            },

            listModules: [],
            SALES: {},
            DRAFTING: {},
            PERMITTING: {},
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            projectLoaded() {
                return this.projectSelected != null && this.projectSelected != undefined;
            },

            showDraftingFiles() {
                return this.projectLoaded && this.projectSelected.status >= this.PROJECT_STATUS.DRAFTING
            },

            showPermittingFiles() {
                return this.projectLoaded && this.projectSelected.status >= this.PROJECT_STATUS.PERMITTING_READY_SUBMIT
            },
        },

        methods: {

            getIDServiceProject() {

                if (this.projectSelected != null, this.projectSelected != undefined) {
                    return this.projectSelected.id;
                }

                return 0;
            },

            async getLists() {
                this.listModules = await this.$store.dispatch("moduleModule/List");

                this.SALES = this.listModules.filter(mod => mod.id == 1)[0];
                this.DRAFTING = this.listModules.filter(mod => mod.id == 2)[0];
                this.PERMITTING = this.listModules.filter(mod => mod.id == 3)[0];
            },

            async downloadProposal() {

                this.showLoading();

                if (this.showTotalProposal == null || this.showTotalProposal == undefined || this.showTotalProposal == 0) {
                    this.showTotalProposal = false;
                }

                if (this.showBreakdown == null || this.showBreakdown == undefined || this.showBreakdown == 0) {
                    this.showBreakdown = false;
                }

                this.$vanKirkApi.get(`service/download-proposal/${this.id}/0/false/${this.showTotalProposal}/${this.showBreakdown}`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        var url = window.URL.createObjectURL(blob);
                        window.open(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Error to generate proposal: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Error to generate proposal: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },

            async openGallery() {

                if (this.id != null && this.id != undefined) {

                    let listParams = [];
                    let idLoadCalculation = 0;
                    let idColorSelection = 0;

                    this.listProjects = await this.$store.dispatch("serviceModule/ListProject", this.id);

                    const responseLoadCalculation = await this.$store.dispatch("serviceModule/GetLoadCalculationById", { id: this.id, idAddress: 0 } );
                    if (responseLoadCalculation.success) {
                        idLoadCalculation = responseLoadCalculation.result.id;
                    }

                    const responseColorSelection = await this.$store.dispatch("serviceModule/GetColorSelectionById", this.id);
                    if (responseColorSelection.success) {
                        idColorSelection = responseColorSelection.result.id;
                    }

                    this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                        idService: 0, 
                        idServiceProject: this.id, 
                        onlyCheckExists: false, 
                        idStatus: 0,
                        idPanel: 0,
                        idStage: 0,
                        idUser: 0
                    } );

                    this.listProjects.forEach(itemProject => {

                        let idServiceProject = itemProject.id

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.POOL_STUDIO_IMAGES.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams = [];
                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.FENCE_LOCATION.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.LIGHT_LOCATIONS.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.SOIL_TEST.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.PERGOLAS.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.WATER_FEATURES.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.BBQ.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.POOL_STUDIO_IMAGES.value,
                            idKey: idServiceProject,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.LOAD_CALCULATION.value,
                            typeDocument: TYPE_DOCUMENT.LOAD_CALCULATION_PICTURES.value,
                            idKey: idLoadCalculation,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.SALES.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.COLOR_SELECTION.value,
                            typeDocument: TYPE_DOCUMENT.COLOR_SELECTION_PICTURES.value,
                            idKey: idColorSelection,
                            isImage: true
                        })

                        listParams.push({
                            stageDescription: this.DRAFTING.moduleDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.DRAFTING_IMAGES.value,
                            idKey: idServiceProject,
                            isImage: true
                        })
                    });

                    this.listNote.forEach(itemNote => {

                        listParams.push({
                            stageDescription: itemNote.statusDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.NOTE.value,
                            typeAttachmentDescription: '',
                            typeDocumentDescription: '',
                            idKey: itemNote.id,
                            isImage: true
                        });
                    });

                    this.dialogShowFiles = {
                        show: true,
                        idServiceProject: this.id,
                        showAsCarrousel: true,
                        reload: Math.random(),
                        listParams
                    };
                }
            },

            openScopeOfWork() {

                const idServiceProject = this.getIDServiceProject();

                if (idServiceProject != 0) {

                    this.dialogScopeOfWork = {
                        show: true,
                        idAux: Math.random(),
                        idServiceProject
                    };
                }
            },

            async openDraftingFiles() {

                const idServiceProject = this.getIDServiceProject();
                
                const responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", idServiceProject);

                if (responseDraftingUploadFiles != null && responseDraftingUploadFiles != undefined) {

                    let listParams = [];

                    const idDrafting = responseDraftingUploadFiles.result.id;

                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_POOL_PLAN.value,
                        idKey: idDrafting,
                        isImage: false
                    });

                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_DESIGN_SPECIFICATION.value,
                        idKey: idDrafting,
                        isImage: false
                    });

                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_IMAGES.value,
                        idKey: idDrafting,
                        isImage: true
                    });

                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_POOL_PLAN_SIGNED.value,
                        idKey: idDrafting,
                        isImage: false
                    });

                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_POOL_PLAN_ENGINEER_SIGNED.value,
                        idKey: idDrafting,
                        isImage: false
                    });

                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_BARBECUE.value,
                        idKey: idDrafting,
                        isImage: false
                    });
                    
                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_PERGOLAS.value,
                        idKey: idDrafting,
                        isImage: false
                    });
                    
                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_DRIVEWAY.value,
                        idKey: idDrafting,
                        isImage: false
                    });
                    
                    listParams.push({
                        stageDescription: this.DRAFTING.moduleDescription,
                        typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                        typeDocument: TYPE_DOCUMENT.DRAFTING_LOAD_CALCULATION_SIGNED.value,
                        idKey: idDrafting,
                        isImage: false
                    });

                    this.dialogShowFiles = {
                        show: true,
                        idServiceProject,
                        title: 'Drafting Files',
                        showAsCarrousel: false,
                        showFilters: false,
                        reload: Math.random(),
                        listParams
                    };
                }
            },

            async openPermittingFiles() {

                const idServiceProject = this.getIDServiceProject();

                let listParams = [];

                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value,
                    idKey: idServiceProject,
                    isImage: false
                });

                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_NOC.value,
                    idKey: idServiceProject,
                    isImage: false
                });

                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_DRIVES_LICENSE.value,
                    idKey: idServiceProject,
                    isImage: false
                });

                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_PERMIT_APPLICATION.value,
                    idKey: idServiceProject,
                    isImage: false
                });

                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_PERMIT_CARD.value,
                    idKey: idServiceProject,
                    isImage: false
                });

                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_PLANS_APPROVED.value,
                    idKey: idServiceProject,
                    isImage: false
                });
                
                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_SPECS.value,
                    idKey: idServiceProject,
                    isImage: false
                });
                
                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.PERMITTING_SOIL_REPORT.value,
                    idKey: idServiceProject,
                    isImage: false
                });
                
                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.RECORDED_NOC.value,
                    idKey: idServiceProject,
                    isImage: false
                });
                
                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.SIGNED_AND_SEALED_PLANS.value,
                    idKey: idServiceProject,
                    isImage: false
                });
                
                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.APPROVED_PERMIT.value,
                    idKey: idServiceProject,
                    isImage: false
                });
                
                listParams.push({
                    stageDescription: this.PERMITTING.moduleDescription,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: TYPE_DOCUMENT.SUPPORTING_DOCUMENTS.value,
                    idKey: idServiceProject,
                    isImage: false
                });

                this.dialogShowFiles = {
                    show: true,
                    idServiceProject,
                    title: 'Permitting Files',
                    showAsCarrousel: false,
                    showFilters: false,
                    reload: Math.random(),
                    listParams
                };
            },
        },

        created() {
            this.getLists();            
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>
<template>
    <div class="text-center">
		<v-dialog
			style="overflow: hidden !important;"
			v-model="showUploadFile"
			transition="dialog-top-transition"
			persistent
			width="800"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card style="overflow: hidden !important;">
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Upload File
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>File</label>
							<v-file-input
								ref="fileInput"
								v-model="file" 
								:accept="acceptType"
								:multiple="multipleFiles"
								small-chips
								outlined
								placeholder="Click here to add file"
								prepend-inner-icon="mdi-paperclip"
								prepend-icon=""
							></v-file-input>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
                        <v-btn 
                            class="mx-2"
                            @click="cancel"
                            style="background-color: var(--color__red) !important; color: #ffffff !important"
                        >
                            Cancel
                        </v-btn>

                        <v-btn  
                            class="mx-2"
                            :loading="loading"
                            @click="confirmAddFile"
                        >
                            OK
                        </v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";

	export default {

        components: {
            ActionDialog: ActionDialog,
        },

        mixins: [Helpers],

        props: {

            idKey: {
                type: Number,
                default: 0
            },

			reload: {
				type: Number,
				default: 0
			},

			showUploadFile: {
				type: Boolean,
				default: false
			},
			
            acceptType: {
                type: String,
                default: ".pdf"
            },

			multipleFiles: {
				type: Boolean,
				default: false
			},

            typeAttachment: {
                type: Number,
                default: 0
            },

            typeDocument: {
                type: Number,
                default: 0
            },

			showMsgConfirmation: {
                type: Boolean,
                default: true
            },

			msgConfirmation: {
				type: String,
				default: ""
			}
		},

		
        data: () => ({

			loading: false,
			file: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
		}),

        watch: {
            async "reload"() {
				try {
					this.$refs.fileInput.$refs.input.click()
				}
				catch {
					window.setTimeout( () => { this.$refs.fileInput.$refs.input.click(); }, 500)
				}
				this.clearFields();
            }
        },

        methods: {

            async clearFields() {
				this.file = []
            },
			
            async cancel() {				
				this.$emit('update:showUploadFile', false)
            },

			async confirmAddFile() {

				if (this.file == null || this.file == undefined || this.file.length <= 0) {
                    this.showToast("error", "Warning!", "You need add at least one file!");
				}
				else {

					if (this.showMsgConfirmation) {
 
						this.dialog = {
							show: true,
							headerText: 'Confirmation',
							bodyText: this.msgConfirmation,
							methodConfirm: this.addFile,
							params: 0
						};
					}
				}
			},
			
            async addFile() {

				let attachmentFileRequest = new FormData();

				this.file.forEach(itemFile => {

					const attachmentRequest = {
						id: 0,
						idKey: this.idKey,
						fileName: itemFile.name,
						fileType: itemFile.type,
						fileSize: itemFile.size,
						typeAttachment: this.typeAttachment,
						typeDocument: this.typeDocument,
					}					

					attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
					attachmentFileRequest.append("attachmentFile", itemFile, itemFile.name);
				})


				const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
					attachmentFileRequest, 
					id: 0
				});

				if (result.success) {
					this.$emit('methodConfirmAfterUpload', this.params);
					this.$emit('update:showUploadFile', false)
				}
				else {
					this.showToast("error", "Error!", result.message);
				}
            },
        }
    };
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }
</style>
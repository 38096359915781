<template>
    <div>
        <v-dialog
            v-model="showDialogSalesPerson"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Update Sales Person
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Sales Person</label>
                            <v-combobox
                                v-model="sellerSelected"
                                :items="listSellers" 
                                :rules=[validations.required]
                                item-text="userName"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            setSalesPerson: {
                default: null
            },

			showDialogSalesPerson: {
				default: false
			},
		},

        watch: {
            async "setSalesPerson"() {
				if (this.setSalesPerson != null && this.setSalesPerson != undefined) {
                    this.sellerSelected = this.setSalesPerson;
                }
            },
        },
		
        data: () => ({
			
            sellerSelected: null,
            listSellers: [],

            validations: {
                required: required
            },
		}),

        methods: {

            cancel() {
                this.$emit('update:showDialogSalesPerson', false);
            },

            async confirm() {

                if (this.sellerSelected == null || this.sellerSelected == undefined || this.sellerSelected.id == 0) {
                    this.showToast("error", "Error!", "Please select a sales person!");
                }
                else {
                    const request = {
                        idService: this.id,
                        salesPersonRequest: {
                            idUserResponsible: this.sellerSelected.id
                        }
                    }

                    const result = await this.$store.dispatch("serviceModule/UpdateSalesPerson", request);

                    if (result.success) {
                        this.showToast("success", "Success!", "Sales person updated successfully!");
                    
                        this.$emit('methodConfirmToCall');
                    }
                    this.cancel();
                }
            }
        },

        async created() {
            
            const avaliableSellersRequest = {
                idService: 0,
                idUser: 0,
                startDate: "0001-01-01",
                endDate: "0001-01-01",
                budget: 0,
                idCity: 0,
                allSalesPerson: 1
            }

            this.listSellers = await this.$store.dispatch("serviceModule/ListAvaliableSellers", avaliableSellersRequest);
        }
    };
</script>
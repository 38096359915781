import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{staticStyle:{"overflow":"hidden !important"},attrs:{"transition":"dialog-top-transition","persistent":"","width":"800","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showUploadFile),callback:function ($$v) {_vm.showUploadFile=$$v},expression:"showUploadFile"}},[_c(VCard,{staticStyle:{"overflow":"hidden !important"}},[_c(VCardTitle,{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Upload File ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',[_vm._v("File")]),_c(VFileInput,{ref:"fileInput",attrs:{"accept":_vm.acceptType,"multiple":_vm.multipleFiles,"small-chips":"","outlined":"","placeholder":"Click here to add file","prepend-inner-icon":"mdi-paperclip","prepend-icon":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2",staticStyle:{"background-color":"var(--color__red) !important","color":"#ffffff !important"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"mx-2",attrs:{"loading":_vm.loading},on:{"click":_vm.confirmAddFile}},[_vm._v(" OK ")])],1)])],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
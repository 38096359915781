<template>
    <div>
        <v-dialog
            v-model="showDialogDocusign"
            transition="dialog-top-transition"
            persistent
            width="80%"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Docusign
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <table style="width: 100%; border: none; font-size: 14px; margin-bottom: 20px;">
                                <tr><td class="column"><strong>Document</strong></td><td> {{ typeDocumentDescription }}</td></tr>
                                <tr><td class="column"><strong>Customer Name</strong></td><td> {{ customerInfo.name }}</td></tr>
                                <tr><td class="column"><strong>Customer Email</strong></td><td> {{ customerInfo.email }}</td></tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-data-table
                                :headers="headersHistory"
                                :items="listHistory"
                                :items-per-page="10"
                                no-data-text="No history registered"
                                :hide-default-footer="true"
                            >
                                <template v-slot:item.statusDescription="{ item }">
                                    <v-chip
                                        :color="getStatusColor(item.status)"
                                        outlined
                                        dark
                                    >
                                        {{ item.statusDescription }}
                                    </v-chip>
                                </template>
                                
                                <template v-slot:item.recipientsCompact="{ item }">
                                    <div v-html="item.recipientsCompact"></div>
                                </template>
                                
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :showButtons="{
                                            checkSignature: item.status == 1,
                                            download: true,
                                            cancel2: item.status == 1,
                                        }"
                                        @confirmCheckSignature="checkSignature(item)"
                                        @confirmDownload="confirmDownload(item)"
                                        @confirmCancel2="confirmCancel(item)"
                                    />
                                </template> 
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss v-btn-large"
							outlined
							@click="dismiss"
						>
							Dismiss
						</v-btn>
                        <v-btn
                            v-if="showButtonSendDocument"
                            class="mx-2 v-btn-large"
                            outlined
                            @click="sendDocumentConfirmation"
                        >
                            Send Document
                        </v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import ActionList from "@/components/Layout/ActionList";

	export default {

        components: {
            ActionDialog,
            ActionList
        },

        mixins: [Helpers],

        props: {
			
            idAux: {
                type: Number,
                default: 0
            },
			
            id: {
                type: Number,
                default: 0
            },
			
            typeDocument: {
                type: Object,
                default: null
            },

            customer: {
                type: Object,
                default: null
            },  

			showDialogDocusign: {
				default: false
			},
		},

        watch: {
            async "idAux"() {
                await this.getHistory();
            },
        },

        computed: {
            typeDocumentDescription() {
                if (this.typeDocument != null && this.typeDocument != undefined) {
                    return this.typeDocument.description
                }

                return '';
            },

            customerInfo() {
                if (this.customer != null && this.customer != undefined) {
                    return {
                        name: this.customer.name,
                        email: this.customer.email
                    }
                }

                return {
                    name: '',
                    email: ''
                }
            },

            showButtonSendDocument() {
                return this.listHistory == null ||
                       this.listHistory == undefined ||
                       this.listHistory.length == 0 || 
                       this.listHistory.filter(his => his.status == 1).length == 0
            }
        },
		
        data: () => ({

            listHistory: [],
            headersHistory: [
                { text: "Envelop ID", value: "idEnvelop", sortable: true },
                { text: "Send On", value: "dateRegisterFormatted", sortable: true },
                { text: "Send By", value: "userNameRegister", sortable: true },
                { text: "Recipients", value: "recipientsCompact", sortable: true },
                { text: "Completed On", value: "dateCompletedFormatted", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],
			
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
		}),

        methods: {

            async getHistory() {

                this.showLoading();

                this.listHistory = [];

                const request = {
                    typeDocument: this.typeDocument.value,
                    id: this.id
                }
                
                const docusignResponse = await this.$store.dispatch("docusignModule/GetByTypeDocument", request);

                if (docusignResponse.success == true && docusignResponse.result.id != 0) {
                    this.listHistory = docusignResponse.result;
                }

                this.hideLoading();
            },

            dismiss() {
                this.$emit('methodConfirmToCall');
                this.$emit('update:showDialogDocusign', false);
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1:
                        color = 'var(--color__status_pending)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__red)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async sendDocumentConfirmation() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will SEND the [${this.typeDocumentDescription}] to customer ${this.customerInfo.name} to sign, confirm your decision?`,
                    methodConfirm: this.sendDocument,
                    params: 0
                };

            },

            async sendDocument() {

                this.showLoading();

                const docusignRequest = {
                    id: this.id,
                    typeDocument: this.typeDocument.value
                }

                const result = await this.$store.dispatch("docusignModule/SendDocument", docusignRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Document sent with successfully!");
                    this.dismiss();
                }

                this.hideLoading();
            },

            async confirmCancel(item) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will cancel this document, confirm your decision?`,
                    methodConfirm: this.cancelDocument,
                    params: item.idEnvelop
                };

            },

            async cancelDocument(idEnvelop) {

                this.showLoading();

                const result = await this.$store.dispatch("docusignModule/CancelDocument", idEnvelop);

                if (result.success) {
                    this.showToast("success", "Success!", "The Document was canceled successfully.");
                    this.dismiss();
                }

                this.hideLoading();
            },

            async checkSignature(item) {
                this.showLoading();

                const result = await this.$store.dispatch("docusignModule/CheckStatus", item.idEnvelop);

                if (result.success) {
                    this.showToast("success", "Success!", "The Document was verified successfully.");
                    await this.getHistory();
                }
                else {
                    this.showToast("error", "Error!", "Unable to check signatures status at this time!");
                }

                this.hideLoading();
            },

            async confirmDownload(item) {
                this.docusignDownloadDocument(item.idEnvelop, item.filename);
            }
        },
    };
</script>

<style scoped>
    .column {
        width: 130px;
        height: 30px;
    }
</style>
<template>
    <div>
        <v-dialog
            v-model="showDialogTerms"
            transition="dialog-top-transition"
            persistent
            width="600"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Terms
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <div style="line-height: 25px; font-size: 15px;" v-html="termsText">                                
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Accept terms
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';

	export default {

        mixins: [Helpers],

        props: {
            termsText: {
                default: null
            },

			showDialogTerms: {
				default: false
			},
		},

        methods: {

            cancel() {
                this.$emit('update:showDialogTerms', false);
            },

            async confirm() {

                this.$emit('methodConfirmToCall');
                this.cancel();
            }
        }
    };
</script>
<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Addendum</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceRequest.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>
        
        <v-stepper 
            alt-labels
            v-model="steps"
            style="box-shadow: none;"
        >
            <v-layout align-center justify-center>
                <v-row style="display: contents !important;">
                    <v-col>
                        <v-stepper-header
                            style="box-shadow: none; "
                        >
                            <v-stepper-step
                                :complete="steps > 1"
                                step="1"
                            >
                                Check Items
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="steps > 2"
                                step="2"
                            >
                                Addendum
                            </v-stepper-step>

                        </v-stepper-header>
                    </v-col>
                </v-row>
            </v-layout>
            <v-stepper-items>

                <!-- STEP 1: CHECK ITEMS  -->

                <v-stepper-content step="1">
                    
                    <JobInformation 
                        :id="serviceRequest.id"
                        :project="projectSelected"
                        :refreshJob="refreshJob"
                        :showJobSummary="true"
                        :showButtonsCreateEditProject="true"
                        :showButtonsCreateEditVersion="false"
                        v-on:changeProject="updateProject"
                    />
                    
                    <v-row
                        v-if="projectSelected != null && projectSelected != undefined"
                    >
                        <v-col
                            style="padding: 0px !important;"
                            cols="12"
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-text-field
                                            v-model="searchProduct"
                                            label="Search Product"
                                            prepend-inner-icon="mdi mdi-magnify"
                                            single-line
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-treeview
                                            :items="listTreeViewProduct"
                                            :search="searchProduct"
                                            :open.sync="arrayNode"
                                            @update:open="onOpenTreeView"
                                            :transition="true"
                                            open-on-click
                                            rounded
                                            hoverable
                                        >
                                            <template v-slot:prepend="{ item }">
                                                <v-icon style="color: var(--color__main);" v-if="item.type == 'Category'">
                                                    mdi-shape-outline
                                                </v-icon>
                                                <v-icon style="color: var(--color__main);" v-if="item.type == 'SubCategory'">
                                                    mdi-content-duplicate
                                                </v-icon>
                                                <v-icon style="color: var(--color__cinza_escuro);" v-if="item.type == 'Product'">
                                                    mdi-package-variant-closed
                                                </v-icon>
                                            </template>
                                            <template v-slot:label="{ item }">
                                                <v-row>
                                                    <v-col
                                                        cols="6"
                                                    >
                                                        <span :style="item.type == 'Category' || item.type == 'SubCategory' ? 'color: var(--color__main);' : 'color: var(--color__cinza_escuro);'"> 
                                                            {{ item.name }} 
                                                            <span style="font-size: 20px; color: red; font-weight: bold;" v-if="isProductMandatory(item) == true"> *</span>
                                                            <span style="font-size: 10px;" v-if="item.details != ''"><br />{{ item.details }}</span>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.highlight == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.highlight == 1"
                                                                        style="color: var(--color__alert); margin-left: 5px;" 
                                                                    >
                                                                        mdi-alert-octagram-outline
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ item.highlightDescription }}</span>
                                                            </v-tooltip>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.customOrder == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.customOrder == 1"
                                                                        style="color: var(--color__alert); margin-left: 5px;" 
                                                                    >
                                                                        mdi-package-variant-plus
                                                                    </v-icon>
                                                                </template>
                                                                <span>Custom Order</span>
                                                            </v-tooltip>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.stylable == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.stylable == 1"
                                                                        style="color: var(--color__blue); margin-left: 5px;" 
                                                                    >
                                                                        mdi-palette-swatch-outline
                                                                    </v-icon>
                                                                </template>
                                                                <span>Stylable</span>
                                                            </v-tooltip>                                                            
                                                        </span>
                                                    </v-col>
                                                    <v-col
                                                        style="text-align:right;"
                                                        v-if="item.type == 'Category' || item.type == 'SubCategory'"
                                                        cols="6"
                                                    >
                                                        {{ item.children.filter(chd => chd.qty != "0.00" && chd.qty != "").length }} / {{ item.children.length }}
                                                    </v-col>
                                                    <v-col
                                                        v-if="item.type == 'Product'"
                                                        style="text-align: right;"
                                                        cols="2"
                                                    >
                                                        <v-text-field
                                                            v-if="item.unityFieldType == 1"
                                                            :id="'productQty' + item.idTable"
                                                            name="qtyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            v-model="item.newQty"
                                                            v-formatMoney="moneyFormat"
                                                            :suffix="item.unitySymbol"
                                                            :rules=[validations.mandatoryProduct(item)]
                                                            dense
                                                            single-line
                                                            outlined
                                                            @blur="updateItemsProduct"
                                                        >
                                                        </v-text-field>
                                                        <v-text-field
                                                            v-else
                                                            :id="'productQty' + item.idTable"
                                                            name="qtyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            v-mask="'####'"
                                                            v-model="item.newQty"
                                                            :suffix="item.unitySymbol"
                                                            :rules=[validations.mandatoryProduct(item)]
                                                            dense
                                                            single-line
                                                            outlined
                                                            @blur="updateItemsProduct"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        v-if="item.type == 'Product' && userLoggedGetters.profile.systemAdmin == 1"
                                                        style="text-align: right;"
                                                        cols="2"
                                                    >
                                                        <v-text-field
                                                            name="moneyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            prefix="$"
                                                            v-model="item.priceFormattedWithoutSymbol"
                                                            v-formatMoney="moneyFormat"
                                                            :rules=[validations.mandatoryProduct(item)]
                                                            dense
                                                            single-line
                                                            outlined
                                                            @blur="updateItemsProduct"
                                                            :disabled="userLoggedGetters.profile.systemAdmin != 1"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        v-if="item.type == 'Product'"
                                                        style="text-align: right;"
                                                        cols="2"
                                                    >
                                                        <v-text-field
                                                            name="moneyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            prefix="$"
                                                            v-model="item.total"
                                                            v-formatMoney="moneyFormat"
                                                            disabled
                                                            dense
                                                            single-line
                                                            outlined
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-treeview>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-col>

                        <v-col
                            style="padding: 0px !important;"
                            cols="12"
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Selected/Updated Items
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                            style="cursor: pointer;"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Category
                                                        </th>
                                                        <th class="text-left">
                                                            Sub Category
                                                        </th>
                                                        <th class="text-left" >
                                                            Product
                                                        </th>
                                                        <th class="text-center">
                                                            Qty
                                                        </th>
                                                        <th class="text-center">
                                                            New Qty
                                                        </th>
                                                        <th class="text-center">
                                                            &nbsp;
                                                        </th>
                                                        <th class="text-center">
                                                            &nbsp;
                                                        </th>
                                                        <th class="text-center">
                                                            &nbsp;
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds" :key="index"
                                                        @click="focusInput(item)"
                                                    >
                                                        <td :class="item.newQty == '0' ? 'hachurado' : item.newQty != item.qty ? 'itemUpdated' : ''">{{ item.categoryDescription }}</td>
                                                        <td :class="item.newQty == '0' ? 'hachurado' : item.newQty != item.qty ? 'itemUpdated' : ''">{{ item.subCategoryDescription }}</td>
                                                        <td :class="item.newQty == '0' ? 'hachurado' : item.newQty != item.qty ? 'itemUpdated' : ''">{{ item.productDescription }}</td>
                                                        <td :class="item.newQty == '0' ? 'text-center hachurado' : item.newQty != item.qty ? 'text-center itemUpdated' : 'text-center'">{{ item.qty }} <span style="font-size: 10px !important; font-weight: bold !important;">{{ item.unitySymbol }}</span></td>
                                                        <td :class="item.newQty == '0' ? 'text-center hachurado' : item.newQty != item.qty ? 'text-center itemUpdated' : 'text-center'">{{ item.newQty }} <span style="font-size: 10px !important; font-weight: bold !important;">{{ item.unitySymbol }}</span></td>
                                                        <td>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        class="btnActions"
                                                                        style="width: 35px; height:35px !important;"
                                                                        icon 
                                                                        small 
                                                                        outlined
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Delete Item</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn 
                                                                        class="btnActions"
                                                                        :style="item.note != null && item.note != undefined && item.note != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                                                        icon 
                                                                        small 
                                                                        outlined
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon small @click="showItemNote(item)">mdi-message-bulleted</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Add/Remove Notes</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn 
                                                                        class="btnActions"
                                                                        style="width: 35px; height:35px !important;"
                                                                        icon 
                                                                        small 
                                                                        outlined
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon small @click="showProductsFiles(item)">mdi-folder-multiple-image</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>View Image</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>

                                    <v-dialog
                                        v-model="showDialogItemNote"
                                        transition="dialog-top-transition"
                                        persistent
                                        width="800"
                                        :fullscreen="$vuetify.breakpoint.mobile"
                                    >
                                    
                                        <v-card>
                                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                                {{ itemDescription }}
                                            </v-card-title>

                                            <v-card-text style="margin-top: 20px;">
                                                <v-row>
                                                    <v-col 
                                                        cols="12"
                                                        lg="12"
                                                        md="12"
                                                        sm="12"
                                                    >
                                                        <label>Notes</label>
                                                        
                                                        <v-textarea
                                                            v-model="itemNote"
                                                            single-line
                                                            rows="3"
                                                            row-height="30"
                                                            outlined
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <div style="width: 100%; text-align: center;">
                                                    <v-btn
                                                        class="mx-2 buttonCancelDialog"
                                                        style=""
                                                        outlined
                                                        @click="showDialogItemNote = false"
                                                    >
                                                        Cancel
                                                    </v-btn>

                                                    <v-btn
                                                        class="mx-2"
                                                        outlined
                                                        @click="saveItemNote()"
                                                    >
                                                        Save
                                                    </v-btn>
                                                </div>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                </v-card>
                                
                            </v-card-text>
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-row>
                                            <v-col 
                                                cols="6"
                                                lg="6"
                                                md="6"
                                                sm="6"
                                            >
                                                Custom Items
                                            </v-col>
                                            <v-col 
                                                cols="6"
                                                style="text-align: right;"
                                            >
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            style="width: 45px;"
                                                            class="btnActions"
                                                            outlined
                                                            fab
                                                            x-small
                                                            v-on="on"
                                                            @click="showDialogCustomItemAndClearFields()" 
                                                            small
                                                        >
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Add New Custom Item</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                            style="cursor: pointer;"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 50%;" >
                                                            Description
                                                        </th>
                                                        <th class="text-center">
                                                            Qty
                                                        </th>
                                                        <th>
                                                            Price
                                                        </th>
                                                        <th>
                                                            Total
                                                        </th>
                                                        <th class="text-center">
                                                            &nbsp;
                                                        </th>
                                                        <th class="text-center">
                                                            &nbsp;
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listCustomItems" :key="index"
                                                    >
                                                        <td>{{ item.description }}</td>
                                                        <td class="text-center">{{ item.qty }}</td>
                                                        <td>$ {{ item.priceFormatted }}</td>
                                                        <td>$ {{ item.total }}</td>
                                                        <td>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        class="btnActions"
                                                                        style="width: 35px; height:35px !important;"
                                                                        icon 
                                                                        small 
                                                                        outlined
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon small @click="showDialogCustomItemAndFillFields(item.id)">mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Edit Item</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        class="btnActions"
                                                                        style="width: 35px; height:35px !important;"
                                                                        icon 
                                                                        small 
                                                                        outlined
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon small @click="deleteItemCustom(item.id)">mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Delete Item</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>

                                    <v-dialog
                                        v-model="showDialogCustomItem"
                                        transition="dialog-top-transition"
                                        persistent
                                        width="1000"
                                        :fullscreen="$vuetify.breakpoint.mobile"
                                    >
                                    
                                        <v-card>
                                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                                Add New Custom Item
                                            </v-card-title>

                                            <v-card-text style="margin-top: 20px;">
                                                <v-form
                                                    ref="formCustomItem"
                                                    v-model="validFormCustomItem"
                                                    lazy-validation
                                                >
                                                    <v-row>
                                                        <v-col 
                                                            cols="12"
                                                            lg="6"
                                                            md="4"
                                                            sm="12"
                                                        >
                                                            <label>Description</label>
                                                            <v-text-field
                                                                v-model="serviceItemCustomRequest.description" 
                                                                :rules=[validations.required]
                                                                single-line
                                                                outlined
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col 
                                                            style="text-align: center;"
                                                            cols="12"
                                                            lg="2"
                                                            md="4"
                                                            sm="12"
                                                        >
                                                            <label>Qty</label>
                                                            <v-text-field
                                                                name="qtyField"
                                                                style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                                v-model="serviceItemCustomRequest.qty"
                                                                v-formatMoney="moneyFormat"
                                                                v-mask="'####'"
                                                                suffix="UN"
                                                                single-line
                                                                outlined
                                                                @blur="calculateItemCustomTotal()"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                            
                                                        </v-col>
                                                        <v-col 
                                                            style="text-align: right;"
                                                            cols="12"
                                                            lg="2"
                                                            md="4"
                                                            sm="12"
                                                        >
                                                            <label>Price</label>
                                                            <v-text-field
                                                                name="moneyField"
                                                                style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                                prefix="$"
                                                                v-model="serviceItemCustomRequest.price"
                                                                v-formatMoney="moneyFormat"
                                                                single-line
                                                                outlined
                                                                @blur="calculateItemCustomTotal()"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                            
                                                        </v-col>
                                                        <v-col 
                                                            style="text-align: right;"
                                                            cols="12"
                                                            lg="2"
                                                            md="4"
                                                            sm="12"
                                                        >
                                                            <label>Total</label>
                                                            <v-text-field
                                                                name="moneyField"
                                                                style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                                prefix="$"
                                                                v-model="serviceItemCustomRequest.total"
                                                                v-formatMoney="moneyFormat"
                                                                disabled
                                                                single-line
                                                                outlined
                                                                dense
                                                            >
                                                            </v-text-field>
                                                            
                                                        </v-col>

                                                    </v-row>
                                                    <v-row>
                                                        <v-col 
                                                            cols="12"
                                                            lg="12"
                                                            md="12"
                                                            sm="12"
                                                        >
                                                            <label>Notes</label>
                                                            
                                                            <v-textarea
                                                                v-model="serviceItemCustomRequest.note"
                                                                single-line
                                                                rows="3"
                                                                row-height="30"
                                                                outlined
                                                                dense
                                                            >
                                                            </v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <div style="width: 100%; text-align: center;">
                                                    <v-btn
                                                        class="mx-2 buttonCancelDialog"
                                                        style=""
                                                        outlined
                                                        @click="showDialogCustomItem = false"
                                                    >
                                                        Cancel
                                                    </v-btn>

                                                    <v-btn
                                                        class="mx-2"
                                                        outlined
                                                        @click="saveItemCustom()"
                                                    >
                                                        Save
                                                    </v-btn>
                                                </div>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>
                        
                    </v-row>
                </v-stepper-content>

                <!-- STEP 2: ADDENDUM  -->

                <v-stepper-content step="2">
                    
                    <JobInformation 
                        :id="serviceRequest.id"
                        :showJobSummary="true"
                        :project="projectSelected"
                        :refreshJob="refreshJob"
                        :showButtonsCreateEditProject="serviceRequest.status < 6"
                        :showButtonsCreateEditVersion="serviceRequest.status < 6"
                        v-on:changeProject="updateProject"
                    />

                    <!-- PRODUCT -->
                    <v-row 
                        v-if="projectSelected != null && projectSelected != undefined"
                    >
                        <v-col
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-row>
                                            <v-col
                                                cols="9"
                                            >
                                                Selected/Updated Items
                                            </v-col>
                                            <v-col
                                                style="text-align:right;"
                                                cols="3"
                                            >
                                                <v-tooltip top v-if="!showMoreDetailProducts">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" @click="showMoreDetailProducts = true"  v-if="!showMoreDetailProducts">mdi-eye-off</v-icon>
                                                    </template>
                                                    <span>See more details</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="showMoreDetailProducts">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" @click="showMoreDetailProducts = false" v-if="showMoreDetailProducts">mdi-eye</v-icon>
                                                    </template>
                                                    <span>Hide more details</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Category
                                                        </th>
                                                        <th class="text-left">
                                                            Sub Category
                                                        </th>
                                                        <th class="text-left">
                                                            Product
                                                        </th>
                                                        <th class="text-center">
                                                            Qty
                                                        </th>
                                                        <th class="text-center">
                                                            New Qty
                                                        </th>
                                                        <th class="text-right" v-if="showMoreDetailProducts">
                                                            Price
                                                        </th>
                                                        <th class="text-right" v-if="showMoreDetailProducts">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.qty != prd.newQty)" :key="index"
                                                    >
                                                        <td :class="item.newQty == '0' ? 'hachurado' : item.newQty != item.qty ? 'itemUpdated' : ''">{{ item.categoryDescription }}</td>
                                                        <td :class="item.newQty == '0' ? 'hachurado' : item.newQty != item.qty ? 'itemUpdated' : ''">{{ item.subCategoryDescription }}</td>
                                                        <td :class="item.newQty == '0' ? 'hachurado' : item.newQty != item.qty ? 'itemUpdated' : ''">{{ item.productDescription }}</td>
                                                        <td :class="item.newQty == '0' ? 'text-center hachurado' : item.newQty != item.qty ? 'text-center itemUpdated' : 'text-center'">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                        <td :class="item.newQty == '0' ? 'text-center hachurado' : item.newQty != item.qty ? 'text-center itemUpdated' : 'text-center'">{{ item.newQty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                        <td :class="item.newQty == '0' ? 'text-right hachurado' : item.newQty != item.qty ? 'text-right itemUpdated' : 'text-right'" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                        <td :class="item.newQty == '0' ? 'text-right hachurado' : item.newQty != item.qty ? 'text-right itemUpdated' : 'text-right'" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px !important;">
                                                            Total
                                                        </th>
                                                        <th :class="showMoreDetailProducts ? 'text-right' : 'text-center'" style="color: var(--color__main); font-size: 20px !important;">
                                                            {{ totalDiferenceProducts }}
                                                        </th>
                                                    </tr>
                                                    <tr v-if="showMoreDetailProducts">
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px !important;">
                                                            Budget
                                                        </th>
                                                        <th class="text-right"  style="width: 15%; color: var(--color__main); font-size: 20px !important;">
                                                            {{ serviceRequest.estimatedBudgetFormatted }}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>                                
                            </v-card-text>
                        </v-col>
                    </v-row>


                    <v-row 
                        v-if="projectSelected != null && projectSelected != undefined"
                    >
                        <v-col
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Custom Items
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Description
                                                        </th>
                                                        <th class="text-left">
                                                            Qty
                                                        </th>
                                                        <th class="text-right">
                                                            Price
                                                        </th>
                                                        <th class="text-right">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listCustomItems" :key="index"
                                                    >
                                                        <td>{{ item.description }}</td>
                                                        <td>{{ item.qty }}</td>
                                                        <td class="text-right">$ {{ item.priceFormatted }}</td>
                                                        <td class="text-right">$ {{ item.total }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px !important;">
                                                            Total
                                                        </th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px !important;">
                                                            {{ totalCustomItems }}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px !important;">
                                                            Grand Total
                                                        </th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px !important;">
                                                            {{ grandTotalItemsAndCustomItems }}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>                                
                            </v-card-text>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col
                            v-if="addendumCreated"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-col cols="4">
                                            Addendum
                                        </v-col>
                                        <v-col cols="8" style="text-align: right;">
                                            <v-chip
                                                style="font-size: 12px !important;"
                                                :color="statusColorAddendum"
                                                outlined
                                                dark
                                            >
                                                {{ statusDescriptionAddendum }}
                                            </v-chip>

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip
                                                        style="font-size: 12px !important; margin-left: 10px;"
                                                        v-on="on"
                                                        color="primary"
                                                        outlined
                                                        dark
                                                        link
                                                        @click="showDialogDocusign(TYPE_DOCUMENT.ADDENDUM)"
                                                    >
                                                        Docusign
                                                    </v-chip>
                                                </template>
                                                <span>Click to view docusign options</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                lg="5"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 1:</strong> First you must download the addendum and send it to the customer for consideration</span>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="downloadAddendum()"
                                                        >
                                                            <v-icon left>mdi-file-download-outline</v-icon>
                                                            Download PDF Addendum
                                                        </v-btn>
                                                    </template>
                                                    <span>Download PDF</span>
                                                </v-tooltip>

                                            </v-col>
                                            <v-col
                                                style="text-align: center;"
                                                cols="12"
                                                lg="1"
                                                md="12"
                                                sm="12"
                                            >
                                            
                                                <v-divider vertical></v-divider>

                                            </v-col>

                                            <v-col
                                                cols="12"
                                                lg="6"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 2:</strong> Upload the addendum signed to complete this process and proceed to the technical specification phase</span>
                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="uploadSignedAddendum()"
                                                        >
                                                            <v-icon left>mdi-file-upload-outline</v-icon>
                                                            Upload Signed Addendum
                                                        </v-btn>
                                                    </template>
                                                    <span>Upload Signed Addendum</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>
            
        <!-- Buttons: NEXT | BACK -->
        
        <div 
            v-if="addendumCreated"
            class="text-center"
        >
            
            <v-row>
                <v-col 
                    cols="12"
                    lg="11"
                    md="12"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="back()"
                            >
                                Back
                            </v-btn>
                        </template>
                        <span>Back</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <div 
            v-else
            class="text-center"
        >            
            <v-row>
                <v-col 
                    cols="7"
                    lg="7"
                    md="7"
                    sm="8"
                >
                    <v-row>
                        <v-col 
                            style="text-align: right"
                            cols="6"
                            lg="8"
                            md="7"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="cancel"
                                        style="background-color: var(--color__red) !important; color: #ffffff !important;"
                                    >
                                        Cancel
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            style="text-align: center"
                            cols="6"
                            lg="4"
                            md="5"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        :disabled="steps == 1 || projectSelected == null || projectSelected == undefined"
                                        :style="steps == 1 || projectSelected == null || projectSelected == undefined ? '' : 'background-color: var(--color__silver) !important; color: #ffffff !important'"
                                        @click="controlStep('back')"
                                    >
                                        Back
                                    </v-btn>
                                </template>
                                <span>Back Previous Step</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="4"
                >
                    <v-row>
                        <v-col 
                            v-if="steps == 1"
                            style="text-align: left"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        :disabled="projectSelected == null || projectSelected == undefined"
                                        @click="controlStep('next')"
                                    >
                                        Next
                                    </v-btn>
                                </template>
                                <span>Go To Next Step</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            v-if="steps == 2 && addendumCreated == false"
                            style="text-align: left"
                            cols="12"
                            lg="5"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        @click="confirmCreateAddendum()"
                                    >
                                        Create Addendum
                                    </v-btn>
                                </template>
                                <span>Create Addendum</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <UploadFile 
            v-on:methodConfirmAfterUpload="dialogUploadFile.methodAfterUpload"
            :showUploadFile.sync="dialogUploadFile.show"
            :idKey="dialogUploadFile.idKey"
            :reload="dialogUploadFile.reload"
            :acceptType="dialogUploadFile.acceptType"
            :typeAttachment="dialogUploadFile.typeAttachment"
            :typeDocument="dialogUploadFile.typeDocument"
            :multipleFiles="dialogUploadFile.multipleFiles"
            :showMsgConfirmation="dialogUploadFile.showMsgConfirmation"
            :msgConfirmation="dialogUploadFile.msgConfirmation"
        />

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :reload="dialogShowFiles.reload"
            :listParams="dialogShowFiles.listParams"
        />

        <DialogTerms
            :termsText="dialogTerms.termsText"
            v-on:methodConfirmToCall="dialogTerms.methodConfirm"
            :showDialogTerms.sync="dialogTerms.show"
        />

        <DialogDocusign 
            v-on:methodConfirmToCall="dialogDocusign.methodConfirm"
            :showDialogDocusign.sync="dialogDocusign.show"
            :idAux="dialogDocusign.idAux"
            :id="dialogDocusign.id"
            :typeDocument="dialogDocusign.typeDocument"
            :customer="dialogDocusign.customer"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBR, formaterDecimalBRServer } from '@/utilities/Utils';
    import UploadFile from "@/components/File/UploadFile";
    import ShowFiles from "@/components/File/ShowFiles";
    import JobInformation from "@/components/Services/JobInformation";
    import DialogTerms from "@/components/Services/DialogTerms";
    import DialogDocusign from "@/components/Services/DialogDocusign";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            UploadFile,
            ShowFiles,
            JobInformation,
            DialogTerms,
            DialogDocusign
        },

        mixins: [Helpers],

        data: vm => ({

            panels: [],

            steps: 1,

			serviceRequest: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                appointmentCitySelected: {
                    description: "",
                    stateSelected: {
                        code: ""
                    }
                }
            },

            listProjects: [],
            projectSelected: null,
            refreshJob: false,

            listProductSelectedsOriginal: [],
            listProductSelecteds: [],

            arrayNode: [],
            searchProduct: "",
            listTreeViewProduct: [],

            idServiceItem: 0,
            itemDescription: "",
            itemNote: "",
            showDialogItemNote: false,

            moneyFormat: {
                prefix: '',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            showMoreDetail: false,

            showMoreDetailProducts: false,
            grandTotalProducts: "$ 0.00",
            totalDiferenceProducts: "$ 0.00",
            grandTotalProductsUnFormatted: 0,
            totalDiferenceProductsUnFormatted: 0,
            estimatedValueZillow: "$ 0.00",

            addendumCreated: false,

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogUploadFile: {
                show: false,
                idKey: 0,
                reload: 0,
                acceptType: ".pdf",
                typeAttachment: 0,
                typeDocument: 0,
                multipleFiles: false,
                showMsgConfirmation: true,
                msgConfirmation: "",
                methodAfterUpload: ( () => {} ),
            },

            dialogShowFiles: {
                show: false,
                reload: 0,
                listParams: []
            },

            validFormCustomItem: true,
            showDialogCustomItem: false,
            listCustomItems: [],
            serviceItemCustomRequest: {
                description: '',
                qty: 1,
                priceFormatted: '0,00',
                price: '0,00',
                total: '0,00',
                note: ''
            },

            dialogTerms: {
                showDialogTerms: null,
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogDocusign: {
                show: false,
                idAux: 0,
                id: 0,
                typeDocument: null,
                customer: null,
                methodConfirm: ( () => {} )
            },

            statusColorAddendum: "var(--color__main)",
            statusDescriptionAddendum: "Completed",
            
            validations: {
                required: required,
                mandatoryProduct (itemProduct) {
                    if (vm.isProductMandatory(itemProduct) == true && (itemProduct.newQty == '0' || itemProduct.newQty == '' || itemProduct.newQty == null))
                        return false
                    else
                        return true
                }
            },

            TYPE_DOCUMENT: TYPE_DOCUMENT

        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            getCustomItemTotal() {

                let total = 0;

                this.listCustomItems.forEach(itemCustom => {
                    let qty = parseFloat(itemCustom.qty);
                    let price = parseFloat(itemCustom.price);

                    total += parseFloat((qty * price).toFixed(2));
                })

                return parseFloat(total);
            },

            totalCustomItems() {

                let total = this.getCustomItemTotal;

                return `$ ${formaterDecimalBR(total)}`;
            },

            grandTotalItemsAndCustomItems() {
                let customItemsTotal = this.getCustomItemTotal;
                
                let grandTotal = customItemsTotal + this.totalDiferenceProductsUnFormatted;

                return `$ ${formaterDecimalBR(grandTotal)}`;
            },

            isProjectPool() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pool == 1;
            },

            isProjectBBQ() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.bbq == 1;
            },

            isProjectPergola() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pergola == 1;
            },
        },

        methods: {
            
            async back() {
                history.go(-1);
            },

            async controlStep (type) {
                if (type === 'next') {

                    let validForm = true;

                    switch (this.steps) {
                        case 1:
                            validForm = await this.validateForm(1);
                            if (validForm) {
                                if (await this.saveItems(0, 0)) {
                                    this.steps = 2; 
                                }
                            }
                        break;

                        case 2:
                            validForm = await this.validateForm(2);
                            if (validForm) {
                                this.steps = 3; 
                            }
                        break;

                        default: 
                            this.steps = 1; 
                        break;
                    }
                    
                    if (validForm == true) {
                        this.refreshJob = !this.refreshJob;
                    }

                }
                else if (type === 'back') {
                    switch (this.steps) {
                        case 3: this.steps = 2; break;
                        case 2: this.steps = 1; break;
                        default: this.steps = 1; break;
                    }
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;
                }
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            byId( a, b ) {
                if ( a.id < b.id ){
                    return -1;
                }
                if ( a.id > b.id ){
                    return 1;
                }
                return 0;
            },

            async updateProject(projectSelected, listProjects) {

                this.projectSelected = projectSelected;
                this.listProjects = listProjects;

                if (projectSelected != null && projectSelected != undefined) {

                    let idServiceProject = await this.getIDProject();                    

                    let responseAddendumCreated = await this.$store.dispatch("serviceModule/CheckAddendumCreated", idServiceProject);

                    this.addendumCreated = false;

                    if (responseAddendumCreated != null && responseAddendumCreated.success == true) {
                        this.addendumCreated = responseAddendumCreated.result == true;
                    }

                    if (this.addendumCreated) {
                        this.steps = 2;
                    }

                    let fromHistory = this.addendumCreated == true;

                    this.listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: idServiceProject, type: 'SERVICE', showOnlyCreatedItems: false, fromHistory: fromHistory });
                    
                    await this.updateItemsProduct();
                    await this.getCustomItems();
                    await this.checkAddendumSigned();

                    this.listProductSelectedsOriginal = [...this.listProductSelecteds];
                }
            },

            async checkAddendumSigned() {

                const statusCompletedColor = "var(--color__main)";
                const statusCompletedDescription = "Completed";
                const statusPendingColor = "var(--color__alert)";
                const statusPendingDescription = "Pending";
                
                const idServiceProject = await this.getIDProject();
                const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE.value, idKey: idServiceProject });

                const addendumUploaded = listAttachments.filter(item => item.typeDocument == TYPE_DOCUMENT.ADDENDUM.value)[0];

                if (addendumUploaded == null || addendumUploaded == undefined) {
                    this.statusColorAddendum = statusPendingColor;
                    this.statusDescriptionAddendum = statusPendingDescription;
                }
                else {
                    this.statusColorAddendum = statusCompletedColor;
                    this.statusDescriptionAddendum = statusCompletedDescription;
                }
            },

            async updateItemsProduct() {

                let idServiceProject = await this.getIDProject();

                if (idServiceProject != 0) {
                
                    this.listProductSelecteds = [];
                    let grandTotal = 0;
                    let grandTotalDiference = 0;
                    this.listTreeViewProduct.forEach(itemCategory => {
                        itemCategory.children.forEach(itemSubCategoryOrProduct => {
                            
                            if (itemSubCategoryOrProduct.type == 'SubCategory') {

                                itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                    if (itemProduct.newQty != null && itemProduct.newQty != undefined && itemProduct.newQty != "") {

                                        const newQty = parseFloat(itemProduct.newQty == '' ? 0 : itemProduct.newQty);
                                        const qty = itemProduct.qty == null || itemProduct.qty == undefined || itemProduct.qty == '' ? '0' : itemProduct.qty;
                                        
                                        if (newQty < 0) {
                                            this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                            itemProduct.newQty = '';
                                            return;
                                        }
                                        else {
                                            // let price = parseFloat(formaterDecimalBRServer(itemProduct.priceFormattedWithoutSymbol));

                                            const total = (newQty * itemProduct.price).toFixed(2);

                                            // itemProduct.price = price;
                                            // itemProduct.total = formaterDecimalBR(total);

                                            this.listProductSelecteds.push({
                                                id: itemProduct.idServiceItem,
                                                idService: idServiceProject,
                                                idCategory: itemCategory.id,
                                                categoryDescription: itemCategory.name,
                                                idSubCategory: itemSubCategoryOrProduct.id,
                                                subCategoryDescription: itemSubCategoryOrProduct.name,
                                                productDescription: itemProduct.name,
                                                idProduct: itemProduct.idTable,
                                                mandatoryPool: itemProduct.mandatoryPool,
                                                mandatoryBBQ: itemProduct.mandatoryBBQ,
                                                mandatoryPergola: itemProduct.mandatoryPergola,
                                                needsApproval: itemProduct.needsApproval,
                                                qty: qty,
                                                newQty: newQty,
                                                unitySymbol: itemProduct.unitySymbol,
                                                price: formaterDecimalBR(itemProduct.price),
                                                total: formaterDecimalBR(total),
                                            })
                                            grandTotal += parseFloat(total);

                                            if (qty != newQty) {
                                                grandTotalDiference += parseFloat(total);
                                            }
                                        }
                                    }
                                    else {
                                        itemProduct.qty = '0';
                                    }
                                });
                            }
                            else {
                                
                                if (itemSubCategoryOrProduct.newQty != null && itemSubCategoryOrProduct.newQty != undefined && itemSubCategoryOrProduct.newQty != "" ) {

                                    const newQty = parseFloat(itemSubCategoryOrProduct.newQty == '' ? 0 : itemSubCategoryOrProduct.newQty);
                                    const qty = itemSubCategoryOrProduct.qty == null || itemSubCategoryOrProduct.qty == undefined || itemSubCategoryOrProduct.qty == '' ? '0' : itemSubCategoryOrProduct.qty;

                                    if (newQty < 0) {
                                        this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                        itemSubCategoryOrProduct.newQty = '';
                                        return;
                                    }
                                    else {

                                        // let price = parseFloat(formaterDecimalBRServer(itemSubCategoryOrProduct.priceFormattedWithoutSymbol));
                                        
                                        // let total = (newQty * price).toFixed(2);
                                        const total = (newQty * itemSubCategoryOrProduct.price).toFixed(2);

                                        // itemSubCategoryOrProduct.price = price;
                                        // itemSubCategoryOrProduct.total = formaterDecimalBR(total);

                                        this.listProductSelecteds.push({
                                            id: itemSubCategoryOrProduct.idServiceItem,
                                            idService: idServiceProject,
                                            idCategory: itemCategory.id,
                                            categoryDescription: itemCategory.name,
                                            idSubCategory: 0,
                                            subCategoryDescription: "",
                                            productDescription: itemSubCategoryOrProduct.name,
                                            idProduct: itemSubCategoryOrProduct.idTable,
                                            mandatoryPool: itemSubCategoryOrProduct.mandatoryPool,
                                            mandatoryBBQ: itemSubCategoryOrProduct.mandatoryBBQ,
                                            mandatoryPergola: itemSubCategoryOrProduct.mandatoryPergola,
                                            needsApproval: itemSubCategoryOrProduct.needsApproval,
                                            qty: qty,
                                            newQty: newQty,
                                            unitySymbol: itemSubCategoryOrProduct.unitySymbol,
                                            price: formaterDecimalBR(itemSubCategoryOrProduct.price),
                                            total: itemSubCategoryOrProduct.total,
                                        })
                                        grandTotal += parseFloat(total);

                                        if (qty != newQty) {
                                            grandTotalDiference += parseFloat(total);
                                        }
                                    }
                                }
                                else {
                                    itemSubCategoryOrProduct.qty = '0'
                                }
                            }
                        });
                    });

                    this.listProductSelectedsOriginal.forEach(itemOriginal => {
                        let itemProduct = this.listProductSelecteds.filter(prd => prd.idProduct == itemOriginal.idProduct)[0];

                        if (itemProduct != null && itemProduct != undefined) {
                            itemProduct.qty = itemOriginal.qty
                        }
                        else {
                            let itemOriginalClone = {...itemOriginal};
                            itemOriginalClone.newQty = "0";
                            this.listProductSelecteds.push(itemOriginalClone);
                        }
                    })

                    this.listProductSelecteds.sort(this.byId);

                    this.grandTotalProductsUnFormatted = grandTotal;
                    this.grandTotalProducts = `$ ${formaterDecimalBR(grandTotal)}`;
                    this.totalDiferenceProductsUnFormatted = grandTotalDiference;
                    this.totalDiferenceProducts = `$ ${formaterDecimalBR(grandTotalDiference)}`;
                }
            },

            focusInput(item) {
                this.arrayNode = []
                this.arrayNode.push(item.idCategory);
                if (item.idSubCategory != null && item.idSubCategory != undefined && item.idSubCategory != 0)  {
                    this.arrayNode.push(item.idSubCategory);
                }

                setTimeout(() => {
                    let txtComponent = document.getElementById(`productQty${item.idProduct}`);
                    txtComponent.focus();
                }, 100);

            },

            onOpenTreeView(items) {
                this.arrayNode = items
            },

            async cancel() {
                this.$router.push({ path: "/sales/pipeline" });
            },

            async deleteItem(item) {
                
                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                if (itemProduct.idTable == item.idProduct) {
                                    itemProduct.newQty = '0'
                                }
                            });
                        }
                        else {
                            if (itemSubCategoryOrProduct.idTable == item.idProduct) {
                                itemSubCategoryOrProduct.newQty = '0'
                            }
                        }
                    });
                });


                await this.updateItemsProduct();
            },

            async checkMandatory() {

                let allFieldsOK = true;
                let listProductName = ''

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                allFieldsOK = this.validations.mandatoryProduct(itemProduct);

                                if (allFieldsOK == false) {
                                    listProductName += ` - ${itemProduct.name}<br />`;
                                }
                            });
                        }
                        else {
                            allFieldsOK = this.validations.mandatoryProduct(itemSubCategoryOrProduct);

                            if (allFieldsOK == false) {
                                listProductName += ` - ${itemSubCategoryOrProduct.name}<br />`;
                            }
                        }
                    });
                });

                if (listProductName != '') {
                    this.showToast("error", "Error!", `The items below are mandatory: <br /> <br /> ${listProductName}`);
                }

                return listProductName == '';
            },

            isProductMandatory(itemProduct) {
                return (itemProduct.mandatoryPool == 1    && this.isProjectPool == true) ||
                       (itemProduct.mandatoryBBQ == 1     && this.isProjectBBQ == true)  ||
                       (itemProduct.mandatoryPergola == 1 && this.isProjectPergola == true)
            },

            async validateForm(step) {

                if (step == 1) {

                    if (this.projectSelected == null || this.projectSelected == undefined) {
                        this.showToast("error", "Error!", "No project selected.");
                        return false;
                    }
                    else if (this.listProductSelecteds.length == 0 ) {
                        this.showToast("error", "Error!", "No products selected. You must select at least one.");
                        return false;
                    }
                    else if (await this.checkMandatory()) {
                        return true;
                    }
                }
                else if (step == 2) {

                    return true;
                }
                else {
                    return true;
                }
            },

            async checkExistsDeletedItemsLinkedWithWorkOrder() {
                const idServiceProject = await this.getIDProject();

                const existsDeletedItems = this.listProductSelecteds.filter(prd => prd.newQty == 0);

                if (existsDeletedItems != null && existsDeletedItems != undefined && existsDeletedItems.length > 0) {
                    return await this.$store.dispatch("workOrderModule/CheckExistsLinkedItems", idServiceProject);
                }
                else {
                    return 0;
                }
            },

            async confirmCreateAddendum() {

                const idWorkOrder = await this.checkExistsDeletedItemsLinkedWithWorkOrder();

                if (idWorkOrder != 0) {
                    const listProductsDeleteds = this.listProductSelecteds.filter(prd => prd.newQty == 0);

                    let products = "";

                    listProductsDeleteds.forEach(item => {
                        products += `- ${item.productDescription} <br />`;
                    })

                    this.showToast("warning", "Warning!", `The deleted products below have links in WorkOrder ID: ${idWorkOrder}, please check! <br /><br /> ${products}`);
                }
                else {

                    this.dialogTerms = {
                        termsText: `Will this addendum affect or change any aspects of the color selection?<br />
                        If so, please ensure that the necessary adjustments are made prior to submitting the addendum. <br />
                        Additionally, will this addendum impact any aspects of the plans? <br />
                        If new plans are required, will we need to submit a revision? <br />
                        If so, have the drafter and permit technician been informed? <br />
                        Please note that failure to address these questions will result in you being fully responsible for any consequences and associated costs. <br />
                        Thank you for your attention to this matter.`,
                        show: true,
                        methodConfirm: ( () => { 
                            const projectAndVersion = `${this.projectSelected.name} - [${this.projectSelected.versionName}] <br />`;
                            this.dialog = {
                                show: true,
                                headerText: 'Confirmation',
                                bodyText: `Would like to create a new ADDENDUM about the project/version below? <br /><br /> ${projectAndVersion}`,
                                methodConfirm: this.createAddendum,
                                params: 0
                            };
                        })
                    }

                }
            },

            async createAddendum() {
                
                this.showLoading();

                this.saveItems(1, 0);
                this.addendumCreated = true;

                this.hideLoading();
            },

            uploadSignedAddendum() {
                this.dialogUploadFile = {
                    show: true,
                    idKey: this.id,
                    reload: Math.random(),
                    acceptType: ".pdf",
                    typeAttachment: TYPE_ATTACHMENT.SERVICE.value,
                    typeDocument: TYPE_DOCUMENT.ADDENDUM.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "You are about to upload the signed addendum to the system, and, from this moment on, this addendum will be the official proposal, do you confirm this decision?",
                    methodAfterUpload: this.uploadAddendumSuccess,
                };
            },

            async uploadAddendumSuccess() {
                
                this.showLoading();
                
                await this.saveItems(1, 1);

                this.hideLoading();
            },

            async downloadAddendum() {

                this.showLoading();

                let idServiceProject = await this.getIDProject();

                this.$vanKirkApi.get(`service/download-proposal/${this.id}/${idServiceProject}/true/true/false`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        var url = window.URL.createObjectURL(blob);
                        window.open(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Error to generate addendum: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Error to generate addendum: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },

            async showItemNote(item) {
                this.idServiceItem = item.id;
                this.itemDescription = item.productDescription;
                this.itemNote = item.note;
                this.showDialogItemNote = true;
            },

            async saveItemNote() {

                let serviceItemNoteRequest = {
                    id: this.idServiceItem,
                    notes: this.itemNote
                }
                
                const result = await this.$store.dispatch("serviceModule/UpdateItemNote", serviceItemNoteRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    this.showDialogItemNote = false;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            async showProductsFiles(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT.value,
                    idKey: itemProduct.idProduct,
                    isImage: true
                })

                this.dialogShowFiles = {
                    show: true,
                    reload: Math.random(),
                    listParams
                };
            },

            //CUSTOM ITEM

            async getCustomItems() {

                let idServiceProject = await this.getIDProject();

                const listCustomItems = await this.$store.dispatch("serviceModule/ListItemsCustom", idServiceProject);

                if (listCustomItems != null && listCustomItems != undefined && listCustomItems.length > 0) {

                    this.listCustomItems = [];

                    listCustomItems.forEach(itemCustom => {

                        this.listCustomItems.push({
                            id: itemCustom.id,
                            description: itemCustom.description,
                            qty: itemCustom.qty,
                            priceFormatted: formaterDecimalBR(itemCustom.price),
                            price: itemCustom.price,
                            total: formaterDecimalBR(itemCustom.total),
                            note: itemCustom.note,
                            newRegister: false
                        });
                    });
                }
            },

            async calculateItemCustomTotal() {
                
                let qty = parseFloat(this.serviceItemCustomRequest.qty);
                let price = parseFloat(formaterDecimalBRServer(this.serviceItemCustomRequest.price));

                let total = (qty * price).toFixed(2);

                this.serviceItemCustomRequest.total = total;
            },

            async clearFieldsCustomItem() {

                this.serviceItemCustomRequest = {
                    id: 0,
                    description: '',
                    qty: 1,
                    priceFormatted: '0,00',
                    price: '0,00',
                    total: '0,00',
                    note: ''
                }
            },

            async showDialogCustomItemAndClearFields()  {

                this.clearFieldsCustomItem();
                this.showDialogCustomItem = true;
            },

            async showDialogCustomItemAndFillFields(id) {
                this.clearFieldsCustomItem();
                let itemCustom = this.listCustomItems.filter(cus => cus.id == id)[0];

                if (itemCustom != null && itemCustom != undefined) {

                    this.serviceItemCustomRequest = {
                        id: itemCustom.id,
                        description: itemCustom.description,
                        qty: itemCustom.qty,
                        priceFormatted: formaterDecimalBR(itemCustom.price),
                        price: itemCustom.price,
                        total: itemCustom.total,
                        note: itemCustom.note
                    }

                    this.showDialogCustomItem = true;
                }
            },

            async saveItemCustom() {

                await this.$refs.formCustomItem.validate();

                if (this.validFormCustomItem === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the custom item form. Please review!");
                    return false;
                }
                else {

                    var existsCustomItem = this.listCustomItems.filter(cus => cus.description === this.serviceItemCustomRequest.description);

                    if (existsCustomItem.length > 0 && this.serviceItemCustomRequest.id == 0) {
                        this.showToast("error", "Warning!", "Custom Item already registered!");
                    }
                    else {
                        
                        if (this.serviceItemCustomRequest.id == 0) {
                            
                            this.listCustomItems.push({
                                id: Math.random(),
                                description: this.serviceItemCustomRequest.description,
                                qty: this.serviceItemCustomRequest.qty,
                                priceFormatted: formaterDecimalBR(this.serviceItemCustomRequest.price),
                                price: this.serviceItemCustomRequest.price,
                                total: formaterDecimalBR(this.serviceItemCustomRequest.total),
                                note: this.serviceItemCustomRequest.note,
                                newRegister: true
                            })
                        }
                        else {

                            let itemCustom = this.listCustomItems.filter(cus => cus.id === this.serviceItemCustomRequest.id)[0]

                            if (itemCustom != null && itemCustom != undefined) {

                                itemCustom.description = this.serviceItemCustomRequest.description;
                                itemCustom.qty = this.serviceItemCustomRequest.qty;
                                itemCustom.priceFormatted = formaterDecimalBR(this.serviceItemCustomRequest.price);
                                itemCustom.price = this.serviceItemCustomRequest.price;
                                itemCustom.total = formaterDecimalBR(this.serviceItemCustomRequest.total);
                                itemCustom.note = this.serviceItemCustomRequest.note;
                            }
                        }

                        this.showDialogCustomItem = false;
                    }
                }
            },

            deleteItemCustom(id) {

                let index = 0
                this.listCustomItems.forEach(itemCustom => {
                    if (itemCustom.id == id) {

                        this.listCustomItems.splice(index, 1);
                    }
                    index++;
                });
            },

            async saveItems(oficial, uploadAddendum) {

                const idServiceProject = await this.getIDProject();

                const resultService = await this.$store.dispatch("serviceModule/UpdateNoteScopeOfWork", this.serviceRequest);

                if (resultService.success === true) {

                    let listProducts = [];

                    this.listProductSelecteds.forEach (item => {
                        listProducts.push({
                            id: item.id,
                            idServiceProject: idServiceProject,
                            idProduct: item.idProduct,
                            qty: item.qty.toString(),
                            newQty: item.newQty.toString(),
                            price: item.price,
                            notes: item.notes,
                        })
                    })

                    let result = await this.$store.dispatch("serviceModule/CreateUpdateItems", { serviceItemRequest: listProducts, createHistory: true, oficial: oficial, uploadAddendum: uploadAddendum });

                    if (result.success === true) {

                        //CUSTOM ITEM
                        let listNewCustomItem = [];
                        this.listCustomItems.forEach (itemCustom => {
                            let id = itemCustom.id;

                            if (itemCustom.newRegister === true) {
                                id = 0;
                            }

                            listNewCustomItem.push({
                                id,
                                idServiceProject: idServiceProject,
                                description: itemCustom.description,
                                qty: itemCustom.qty.toString(),
                                price: itemCustom.priceFormatted,
                                total: itemCustom.total,
                                note: itemCustom.note,
                            })
                        })

                        if (listNewCustomItem.length > 0) {
                            result = await this.$store.dispatch("serviceModule/CreateUpdateItemsCustom", listNewCustomItem);
                        }

                        if (result.success !== true) {
                            this.showToast("error", "Warning!", result.message);
                        }
                        else {
                            this.getCustomItems();
                        }

                        if (oficial == 1 && uploadAddendum == 0) {
                            this.showToast("success", "Success!", "Addendum created with successfully!");
                        }
                        else if (uploadAddendum == 1) {
                            this.showToast("success", "Success!", "Addendum uploaded with successfully!");
                            this.back();
                        }
                        return true;
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                        return false;
                    }
                }
                else {
                    this.showToast("error", "Warning!", resultService.message);
                    return false;
                }
            },

            async showDialogDocusign(typeDocument) {

                const customerSelected = this.serviceRequest.customerSelected;

                let customer = {
                    name: '',
                    email: ''
                }

                if (customerSelected != null && customerSelected != undefined) {
                    customer.name = customerSelected.name;
                }
                customer.email = this.serviceRequest.email;

                this.dialogDocusign = {
                    show: true,
                    idAux: Math.random(),
                    id: await this.getIDProject(),
                    typeDocument,
                    customer,
                    methodConfirm: () => {
                        this.getColorSelection();
                    }
                }
            },
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 170px;
    }

    td, th {
        font-size: 12px !important;
    }
</style>

<style>
    input[name="qtyField"] {
        text-align: center !important;
        font-size: 13px;
    }

    input[name="moneyField"] {
        text-align: right !important;
        font-size: 13px;
    }

    .v-text-field__suffix {
        font-size: 12px !important;
        font-weight: bold;
    }

    .hachurado {
        text-decoration: line-through;
        color: var(--color__red) !important
    }

    .itemUpdated {
        color: var(--color__main) !important;
    }
</style>
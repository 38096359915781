import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogMessage),callback:function ($$v) {_vm.showDialogMessage=$$v},expression:"showDialogMessage"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]),_c(VCardText,{staticStyle:{"margin-top":"20px","text-align":"center"}},[_c('strong',[_vm._v(_vm._s(_vm.bodyText))])]),_c(VDivider),_c(VCardActions,[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.OK()}}},[_vm._v(" OK ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"80%","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogDocusign),callback:function ($$v) {_vm.showDialogDocusign=$$v},expression:"showDialogDocusign"}},[_c(VCard,[_c(VCardTitle,{staticClass:"lighten-2 formDialog",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)","font-size":"17px"}},[_vm._v(" Docusign ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('table',{staticStyle:{"width":"100%","border":"none","font-size":"14px","margin-bottom":"20px"}},[_c('tr',[_c('td',{staticClass:"column"},[_c('strong',[_vm._v("Document")])]),_c('td',[_vm._v(" "+_vm._s(_vm.typeDocumentDescription))])]),_c('tr',[_c('td',{staticClass:"column"},[_c('strong',[_vm._v("Customer Name")])]),_c('td',[_vm._v(" "+_vm._s(_vm.customerInfo.name))])]),_c('tr',[_c('td',{staticClass:"column"},[_c('strong',[_vm._v("Customer Email")])]),_c('td',[_vm._v(" "+_vm._s(_vm.customerInfo.email))])])])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headersHistory,"items":_vm.listHistory,"items-per-page":10,"no-data-text":"No history registered","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.statusDescription",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescription)+" ")])]}},{key:"item.recipientsCompact",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.recipientsCompact)}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionList',{attrs:{"showButtons":{
                                            checkSignature: item.status == 1,
                                            download: true,
                                            cancel2: item.status == 1,
                                        }},on:{"confirmCheckSignature":function($event){return _vm.checkSignature(item)},"confirmDownload":function($event){return _vm.confirmDownload(item)},"confirmCancel2":function($event){return _vm.confirmCancel(item)}}})]}}])})],1)],1)],1),_c(VDivider),_c(VCardActions,{staticStyle:{"padding":"15px !important"}},[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2 dialogButtonDismiss v-btn-large",attrs:{"outlined":""},on:{"click":_vm.dismiss}},[_vm._v(" Dismiss ")]),(_vm.showButtonSendDocument)?_c(VBtn,{staticClass:"mx-2 v-btn-large",attrs:{"outlined":""},on:{"click":_vm.sendDocumentConfirmation}},[_vm._v(" Send Document ")]):_vm._e()],1)])],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }